import StatisticsReportCard, {
  StatisticsReportCardSkeleton,
} from "@/admin/dashboard/components/StatisticsReportCard"
import { ContentType } from "@/content-usage/drawer/__generated__/InlineContentDrawerTemplateFragment.graphql"
import {
  QuizSubmissionsOverviewContentHeaderQuery,
  QuizSubmissionsOverviewContentHeaderQuery$variables,
} from "@/content-usage/drawer/quizzes/review-submissions/__generated__/QuizSubmissionsOverviewContentHeaderQuery.graphql"
import { useLabel } from "@/core/context/LabelsContext"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { WebFormSubmissionsUtils } from "@/web-form/utils/webFormSubmissionsUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { formatElapsedTime } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { useSubscribeToInvalidationState } from "react-relay"
import { graphql } from "relay-runtime"

interface QuizSubmissionsOverviewContentHeaderProps extends TestIDProps {
  contentLabel: string
  contentUsageId: GlobalID
  webFormId: GlobalID
  contentType: ContentType
}

function QuizSubmissionsOverviewContentHeader({
  testid,
  contentLabel,
  contentUsageId,
  webFormId,
  contentType,
}: QuizSubmissionsOverviewContentHeaderProps) {
  const isSurvey = contentType === "survey"

  const queryVars: QuizSubmissionsOverviewContentHeaderQuery$variables = {
    webFormId,
    contentUsageId,
    includePercentage: !isSurvey,
  }
  const [{ webForm }, refetch] =
    Relay.useRefetchableQuery<QuizSubmissionsOverviewContentHeaderQuery>(
      graphql`
        query QuizSubmissionsOverviewContentHeaderQuery(
          $webFormId: ID!
          $contentUsageId: ID!
          $includePercentage: Boolean!
        ) {
          webForm: node(id: $webFormId) {
            ... on WebForm {
              submissionsAverageDurationSeconds(contentUsageId: $contentUsageId)
              submissionsAveragePercentage(contentUsageId: $contentUsageId)
                @include(if: $includePercentage)
            }
          }
        }
      `,
      queryVars,
      { fetchPolicy: "network-only" }
    )
  useSubscribeToInvalidationState([webFormId], () => refetch(queryVars))
  const averageDuration = webForm?.submissionsAverageDurationSeconds ?? 0
  const averagePercentage = webForm?.submissionsAveragePercentage ?? 0

  const incomplete = WebFormSubmissionsUtils.useCount({
    contentUsageId,
    webFormId,
    completed: false,
  })
  const completed = WebFormSubmissionsUtils.useCount({
    contentUsageId,
    webFormId,
    completed: true,
  })
  const total = incomplete + completed
  const classes = useStyles()
  const memberLabel = useLabel("admin_member")

  return (
    <div className={classes.header} data-testid={`${testid}.header`}>
      <StatisticsReportCard
        testid={`${testid}.total-submissions`}
        value={`${completed}/${total}`}
        subtitle={"Submissions"}
        tooltipText={`The total number of completed submissions for this ${contentLabel}.`}
        classes={{ root: classes.card }}
      />
      {isSurvey ? (
        <StatisticsReportCard
          testid={`${testid}.completion-rate`}
          value={total ? `${Math.round((completed / total) * 100)}%` : "--"}
          subtitle={"Completion Rate"}
          tooltipText={`The percentage of ${memberLabel.plural} who completed this ${contentLabel} after starting it.`}
          classes={{ root: classes.card }}
        />
      ) : (
        <StatisticsReportCard
          testid={`${testid}.average-score`}
          value={completed ? `${averagePercentage}%` : "--"}
          subtitle={"Average Score"}
          tooltipText={`The average score of completed submissions for this ${contentLabel}.`}
          classes={{ root: classes.card }}
        />
      )}
      <StatisticsReportCard
        testid={`${testid}.average-duration`}
        value={completed ? formatElapsedTime(averageDuration) : "--"}
        subtitle={"Average Time Spent"}
        tooltipText={`The average time taken to complete this ${contentLabel}.`}
        classes={{ root: classes.card }}
      />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  header: {
    display: "flex",
    width: "100%",
    gap: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
  },
  card: {
    width: "100%",
    boxShadow: theme.palette.groovyDepths.insideCard,
  },
}))

export function QuizSubmissionsOverviewContentHeaderSkeleton() {
  const classes = useStyles()
  return (
    <div className={classes.header}>
      <StatisticsReportCardSkeleton classes={{ root: classes.card }} />
      <StatisticsReportCardSkeleton classes={{ root: classes.card }} />
      <StatisticsReportCardSkeleton classes={{ root: classes.card }} />
    </div>
  )
}

export default Relay.withSkeleton({
  skeleton: QuizSubmissionsOverviewContentHeaderSkeleton,
  component: QuizSubmissionsOverviewContentHeader,
})
