import {
  QuizSubmissionsExportCsvButtonMutation,
  QuizSubmissionsExportCsvButtonMutation$variables,
} from "@/content-usage/drawer/quizzes/review-submissions/__generated__/QuizSubmissionsExportCsvButtonMutation.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import { displayErrorToast } from "@components/toast/ToastProvider"
import { DiscoButton } from "@disco-ui"
import { graphql, useMutation } from "react-relay"

type Props = {
  webFormRevisionId: GlobalID
  queryVariables: Partial<QuizSubmissionsExportCsvButtonMutation$variables>
  isDisabled?: boolean
}

export default function QuizSubmissionsExportCsvButton({
  webFormRevisionId,
  queryVariables,
  isDisabled = false,
}: Props) {
  const [commit, isLoading] = useMutation<QuizSubmissionsExportCsvButtonMutation>(graphql`
    mutation QuizSubmissionsExportCsvButtonMutation(
      $webFormRevisionId: ID!
      $contentUsageId: ID
      $orderBy: WebFormSubmissionsOrderByInput
      $search: String
      $isComplete: Boolean
      $completedAfter: DateTime
      $percentageGreaterThanOrEqual: Float
      $percentageLessThan: Float
    ) {
      response: createWebFormSubmissionsCsv(
        webFormRevisionId: $webFormRevisionId
        contentUsageId: $contentUsageId
        orderBy: $orderBy
        search: $search
        isComplete: $isComplete
        completedAfter: $completedAfter
        percentageGreaterThanOrEqual: $percentageGreaterThanOrEqual
        percentageLessThan: $percentageLessThan
      ) {
        downloadCSVLink
        errors {
          field
          message
        }
      }
    }
  `)

  return (
    <DiscoButton
      variant={"outlined"}
      color={"grey"}
      testid={"QuizSubmissionsExportCsvButton"}
      onClick={handleClick}
      disabled={isLoading || isDisabled}
      shouldDisplaySpinner={isLoading}
    >
      {"Export CSV"}
    </DiscoButton>
  )

  function handleClick() {
    commit({
      variables: {
        webFormRevisionId,
        ...queryVariables,
      },
      onCompleted({ response }) {
        if (response.errors?.length) {
          displayErrorToast(response.errors[0].message)
          return
        }
        window.location.href = response.downloadCSVLink!
      },
      onError(err) {
        displayErrorToast(err)
      },
    })
  }
}
