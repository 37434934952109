/**
 * @generated SignedSource<<6331dea1032e55ac17037f5c396cc819>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentSystemTaskKind = "slack_connection" | "calendar_connection" | "custom_profile_field" | "community_agreement" | "default_profile_fields" | "onboarding_complete" | "onboarding_welcome" | "download_mobile_app" | "complete_profile" | "slack_invite" | "add_events_to_calendar" | "connect_slack" | "%future added value";
export type useContentUsageDrawerNavigationQuery$variables = {
  id: string;
};
export type useContentUsageDrawerNavigationQuery$data = {
  readonly curriculum: {
    readonly id?: string;
    readonly modules?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly content: {
            readonly id: string;
            readonly children: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly isLocked: boolean;
                  readonly content: {
                    readonly id: string;
                    readonly systemTaskKind: ContentSystemTaskKind | null;
                  };
                };
              }>;
            } | null;
          };
        };
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment" | "curriculumUtils__getNextCurriculumItem">;
  } | null;
};
export type useContentUsageDrawerNavigationQuery = {
  variables: useContentUsageDrawerNavigationQuery$variables;
  response: useContentUsageDrawerNavigationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ContentUsageNodeConnection",
  "kind": "LinkedField",
  "name": "modules",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentUsageNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentUsage",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Content",
              "kind": "LinkedField",
              "name": "content",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentUsageNodeConnection",
                  "kind": "LinkedField",
                  "name": "children",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ContentUsageNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ContentUsage",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isLocked",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Content",
                              "kind": "LinkedField",
                              "name": "content",
                              "plural": false,
                              "selections": [
                                (v2/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "systemTaskKind",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "viewerPermissions",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useContentUsageDrawerNavigationQuery",
    "selections": [
      {
        "alias": "curriculum",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "usePermissionsFragment"
              },
              {
                "kind": "InlineDataFragmentSpread",
                "name": "curriculumUtils__getNextCurriculumItem",
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ]
              },
              (v3/*: any*/)
            ],
            "type": "Curriculum",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useContentUsageDrawerNavigationQuery",
    "selections": [
      {
        "alias": "curriculum",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Organization",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Product",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Event",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Comment",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Feed",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Post",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "ContentUsage",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "ChatChannel",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Dashboard",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "AssignmentSubmission",
                    "abstractKey": null
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "type": "Curriculum",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e1bbac2f579488ee06ac23d5b88d9ecb",
    "id": null,
    "metadata": {},
    "name": "useContentUsageDrawerNavigationQuery",
    "operationKind": "query",
    "text": "query useContentUsageDrawerNavigationQuery(\n  $id: ID!\n) {\n  curriculum: node(id: $id) {\n    __typename\n    ... on Curriculum {\n      id\n      ...usePermissionsFragment\n      ...curriculumUtils__getNextCurriculumItem\n      modules {\n        edges {\n          node {\n            id\n            content {\n              id\n              children {\n                edges {\n                  node {\n                    id\n                    isLocked\n                    content {\n                      id\n                      systemTaskKind\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment curriculumUtils__getNextCurriculumItem on Curriculum {\n  id\n  modules {\n    edges {\n      node {\n        id\n        content {\n          id\n          children {\n            edges {\n              node {\n                id\n                isLocked\n                content {\n                  id\n                  systemTaskKind\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment usePermissionsFragment on Node {\n  __isNode: __typename\n  __typename\n  ... on Organization {\n    viewerPermissions\n  }\n  ... on Product {\n    viewerPermissions\n  }\n  ... on Event {\n    viewerPermissions\n  }\n  ... on Comment {\n    viewerPermissions\n  }\n  ... on Feed {\n    viewerPermissions\n  }\n  ... on Post {\n    viewerPermissions\n  }\n  ... on ContentUsage {\n    viewerPermissions\n  }\n  ... on ChatChannel {\n    viewerPermissions\n  }\n  ... on Dashboard {\n    viewerPermissions\n  }\n  ... on AssignmentSubmission {\n    viewerPermissions\n  }\n}\n"
  }
};
})();

(node as any).hash = "9b582c91172a4cfc7497d8bcf83ef817";

export default node;
