import QuizSubmissionsQuestionReportsListItem, {
  QUESTION_REPORT_MAX_WIDTH,
} from "@/content-usage/drawer/quizzes/review-submissions/QuizSubmissionsQuestionReportsListItem"
import Relay from "@/relay/relayUtils"
import { WebFormSubmissionsUtils } from "@/web-form/utils/webFormSubmissionsUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import { List, useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import { range } from "lodash"

interface QuizSubmissionsQuestionReportsListProps extends TestIDProps {
  contentUsageId: string
  revisionId: string
}

const QUESTIONS_PER_PAGE = 4

function QuizSubmissionsQuestionReportsList({
  testid = "QuizSubmissionsQuestionReportsList",
  contentUsageId,
  revisionId,
}: QuizSubmissionsQuestionReportsListProps) {
  const {
    data: rawData,
    hasNext,
    loadNext,
    isLoadingNext,
  } = WebFormSubmissionsUtils.usePaginatedQuestions({
    usageId: contentUsageId,
    revisionId,
    first: QUESTIONS_PER_PAGE,
  })
  const questions = Relay.connectionToArray(rawData?.questions)

  const classes = useStyles()

  return (
    <div data-testid={`${testid}.container`} className={classes.container}>
      <ScrollShadowContainer>
        <List data-testid={testid} classes={{ padding: classes.list }}>
          {questions.map((question, i) => (
            <QuizSubmissionsQuestionReportsListItem
              key={question.id}
              testid={`${testid}.item.${i}`}
              question={question}
            />
          ))}
          {hasNext && (
            <DiscoScrolledIntoView
              isLoading={isLoadingNext}
              onScrolledIntoView={() => loadNext(QUESTIONS_PER_PAGE)}
            />
          )}
        </List>
      </ScrollShadowContainer>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "grid",
    overflow: "hidden", // prevent scroll from showing up on drawer
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
  list: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1),
    gap: theme.spacing(1),
  },
}))

function ComponentSkeleton() {
  const theme = useTheme()
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <ScrollShadowContainer>
        <List classes={{ padding: classes.list }}>
          {range(4).map((i) => (
            <Skeleton
              key={i}
              variant={"rect"}
              style={{
                width: "100%",
                height: "300px",
                borderRadius: theme.measure.borderRadius.medium,
                maxWidth: QUESTION_REPORT_MAX_WIDTH,
              }}
            />
          ))}
        </List>
      </ScrollShadowContainer>
    </div>
  )
}

export default Relay.withSkeleton({
  component: QuizSubmissionsQuestionReportsList,
  skeleton: ComponentSkeleton,
})
