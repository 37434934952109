/**
 * @generated SignedSource<<524d594bacc69dc05771cdf211c81c3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type QuizEditorBanner_ContentUsageFragment$data = {
  readonly id: string;
  readonly content: {
    readonly type: ContentType;
    readonly webForm: {
      readonly id: string;
      readonly currentRevision: {
        readonly id: string;
      };
    } | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  readonly " $fragmentType": "QuizEditorBanner_ContentUsageFragment";
};
export type QuizEditorBanner_ContentUsageFragment$key = {
  readonly " $data"?: QuizEditorBanner_ContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuizEditorBanner_ContentUsageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuizEditorBanner_ContentUsageFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WebForm",
          "kind": "LinkedField",
          "name": "webForm",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "WebFormRevision",
              "kind": "LinkedField",
              "name": "currentRevision",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "01d036e3784264103804422587091c3d";

export default node;
