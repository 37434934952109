import { InlineContentDrawerFormStore } from "@/content-usage/drawer/InlineContentDrawerTemplate"
import { CONTENT_DRAWER_FOOTER_HEIGHT } from "@/content-usage/drawer/footer/InlineContentDrawerFooter"
import QuizDrawerQuestionsHeader from "@/content-usage/drawer/quizzes/QuizDrawerQuestionsHeader"
import QuizEditorQuestionsForm from "@/content-usage/drawer/quizzes/QuizEditorQuestionsForm"
import QuizEditorDrawerFooter from "@/content-usage/drawer/quizzes/editor/QuizEditorDrawerFooter"
import useQuizDrawer from "@/content-usage/drawer/quizzes/editor/useQuizEditorDrawer"
import { AdminContentDrawerFormStore } from "@/content/drawer/AdminContentDrawerContent"
import ContentUtils from "@/content/util/contentUtils"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import { useValidateWebFormRevisionInput } from "@/web-form/utils/webFormEditorUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import useDisclosure from "@utils/hook/useDisclosure"
import { TestIDProps } from "@utils/typeUtils"
import { cloneDeep } from "lodash"
import { useEffect, useRef } from "react"

interface QuizEditorDrawerContentProps extends TestIDProps, QuizQuestionProps {
  readOnly?: boolean
  contentLabel: string
}

export interface QuizQuestionProps {
  form: AdminContentDrawerFormStore | InlineContentDrawerFormStore
}

function QuizEditorDrawerContent({
  testid = "QuizEditorDrawerContent",
  form,
  readOnly,
  contentLabel,
}: QuizEditorDrawerContentProps) {
  const { drawer, navigateAway } = useQuizDrawer()
  const { webFormRevision } = form.state.content
  const revisionOnFirstRender = useRef<typeof webFormRevision | null>(null)
  const { handleLeave } = useUnsavedChangesModalContext()
  const { isOpen, onOpen, onClose } = useDisclosure(false)

  const [validate, isValidating] = useValidateWebFormRevisionInput(
    form,
    webFormRevision!,
    ContentUtils.webFormTemplateForContent(form.state.contentType),
    { onSuccess: navigateAway, onError: onClose }
  )

  useEffect(() => {
    revisionOnFirstRender.current = cloneDeep(webFormRevision)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = useStyles()
  return (
    <div className={classes.content}>
      <QuizDrawerQuestionsHeader
        testid={`${testid}.header`}
        title={form.state.content.name || "Untitled"}
        overlineTitle={"Set Up Questions"}
        onBack={readOnly || !hasUnsavedQuestionChanges() ? navigateAway : handleChanges}
        onClose={handleCloseDrawer}
      />
      <ScrollShadowContainer
        testid={testid}
        classes={{ parentContainer: classes.quizQuestionsBackground }}
      >
        <QuizEditorQuestionsForm form={form} readOnly={readOnly} />
      </ScrollShadowContainer>
      <QuizEditorDrawerFooter
        form={form}
        readOnly={readOnly}
        contentLabel={contentLabel}
      />
      <DiscoWarningModal
        testid={"UnsavedChangesModalProvider.DiscoWarningModal"}
        modalContentLabel={"unsaved changes"}
        title={"Discard Question Changes?"}
        description={`You've made changes to the ${contentLabel} questions. Do you want to discard the changes?`}
        isOpen={isOpen}
        onClose={keepChanges}
        cancelButtonText={"Keep Changes"}
        shouldDisplayCancelSpinner={isValidating}
        confirmationButtonProps={{
          onClick: discardChanges,
          children: "Discard Changes",
        }}
      />
    </div>
  )

  function hasUnsavedQuestionChanges() {
    // we can't use form.changedState here because we need to check if changes have been made against the intitial state when the editor (this component) was first rendered, not the initial state of the form
    return (
      JSON.stringify(revisionOnFirstRender.current) !== JSON.stringify(webFormRevision)
    )
  }

  function handleCloseDrawer() {
    // Prevent close if unsaved changes (don't need to check validation here, since there could also be unsaved changes outside questions)
    handleLeave({
      onLeave: drawer.close,
    })
  }

  function handleChanges() {
    // if there have been question changes since the editor was mounted, prompt to discard or keep changes before leaving
    if (hasUnsavedQuestionChanges()) {
      return onOpen()
    }
    navigateAway()
  }

  function discardChanges() {
    // discard changes
    Object.assign(
      form.state.content.webFormRevision!,
      cloneDeep(form.initialState.content.webFormRevision!)
    )

    // Reset key to force re-render of form components
    form.key = Math.random().toString(36).substring(7)
    // clear errors
    form.errors.clear()

    navigateAway()
  }

  function keepChanges() {
    if (isValidating) return
    validate()
  }
}
const useStyles = makeUseStyles((theme) => ({
  quizQuestionsBackground: {
    backgroundColor: theme.palette.background.default,
    width: "100%",
  },
  content: {
    paddingBottom: CONTENT_DRAWER_FOOTER_HEIGHT,
    position: "relative",
    overflow: "hidden",
    height: "100%",
  },
}))

export default QuizEditorDrawerContent
