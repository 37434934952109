import QuizSubmissionResultAnswerList from "@/content-usage/drawer/quizzes/submission-result/QuizSubmissionResultAnswerList"
import QuizSubmissionResultSidebar from "@/content-usage/drawer/quizzes/submission-result/QuizSubmissionResultSidebar"
import { QuizSubmissionUtils } from "@/content-usage/drawer/quizzes/utils/quizSubmissionUtils"
import { UseWebFormSubmissionQueryReturnType } from "@/web-form/utils/webFormQueryUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface QuizSubmissionResultProps
  extends TestIDProps,
    UseWebFormSubmissionQueryReturnType {
  contentLabel: string
}

function QuizSubmissionResult({
  testid = "QuizSubmissionResult",
  contentLabel,
  result,
  submission,
  revision,
}: QuizSubmissionResultProps) {
  const { filteredAnswers, filter, setFilter, currentQuestionId, setCurrentQuestionId } =
    QuizSubmissionUtils.useAnswersListState({ revision, submission })
  const classes = useStyles()

  return (
    <div data-testid={testid} className={classes.container}>
      <QuizSubmissionResultAnswerList
        revision={revision}
        answers={filteredAnswers}
        currentQuestionId={currentQuestionId}
        handleRemoveQuestionId={() => setCurrentQuestionId(null)}
      />
      {submission?.hasAnswerKey && (
        <QuizSubmissionResultSidebar
          result={result}
          answers={filteredAnswers}
          contentLabel={contentLabel}
          filter={filter}
          setFilter={setFilter}
          currentQuestionId={currentQuestionId}
          goToQuestion={(id) => setCurrentQuestionId(id)}
        />
      )}
    </div>
  )
}

const useStyles = makeUseStyles(() => ({
  container: {
    display: "grid",
    overflow: "hidden", // prevent scroll from showing up on drawer
    height: "100%",
    width: "100%",
    gridTemplateColumns: `1fr auto`,
  },
}))

export default observer(QuizSubmissionResult)
