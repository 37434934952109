import { GlobalDrawersConfig, useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { QueryParamAction } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { base64Encode } from "@utils/string/stringUtils"

export interface ContentDefaultCreationData {
  title?: string | null
  body?: string | null
  coverPhotoAssetId?: string | null
  coverPhotoUrl?: string | null
  isAiGenerated?: boolean | null
}

export function useContentUsageDrawer() {
  const drawer = useGlobalDrawer("contentUsage")

  return {
    kind: drawer.kind,
    open: handleOpen,
    close: drawer.close,
    isOpen: drawer.isOpen,
    params: {
      drawerContentUsageId: Relay.toGlobalId("ContentUsage", drawer.params.u),
      drawerTab: drawer.params.drawerTab,
      submissionId: drawer.params.submissionId,
      drawerModuleContentUsageId: drawer.params.drawerModuleContentUsageId,
      drawerUsageEntityId: drawer.params.drawerUsageEntityId,
      drawerUsageEntity: drawer.params.drawerUsageEntity,
      drawerContentType: drawer.params.drawerContentType,
      drawerContentSystemTaskKind: drawer.params.drawerContentSystemTaskKind,
      contentTemplate: drawer.params.contentTemplate,
      drawerAiPrompt: drawer.params.drawerAiPrompt,
      drawerAiReferenceUrl: drawer.params.drawerAiReferenceUrl,
      drawerAiReferenceEmbeddingSourceIds:
        drawer.params.drawerAiReferenceEmbeddingSourceIds,
      drawerAiReferenceModuleContentUsageId:
        drawer.params.drawerAiReferenceModuleContentUsageId,
      drawerSubmissionsTab: drawer.params.drawerSubmissionsTab,
      drawerScormCompletionsTab: drawer.params.drawerScormCompletionsTab,
      drawerQuizTab: drawer.params.drawerQuizTab,
      drawerQuizUseAi: drawer.params.drawerQuizUseAi,
      drawerConnectSlackTab: drawer.params.drawerConnectSlackTab,
      brIds: drawer.params.brIds,
    },
    selectSubmission: handleSubmissionSelect,
    clearCreationParams: handleClearCreationParams,
    setParams: drawer.setParams,
    generateDefaultContentData,
  }

  function generateDefaultContentData(c?: ContentDefaultCreationData): string {
    if (!c) return ""
    return base64Encode(JSON.stringify(c))
  }

  function handleSubmissionSelect(submissionId: GlobalID) {
    drawer.setParams({ drawerSubmissionsTab: "submissions", submissionId })
  }

  function handleOpen(
    params: Omit<GlobalDrawersConfig["contentUsage"]["params"], "u" | "submissionId"> & {
      drawerContentUsageId: GlobalID
    },
    action?: QueryParamAction
  ) {
    drawer.open(
      {
        u: Relay.fromGlobalId(params.drawerContentUsageId).id,
        drawerModuleContentUsageId: params.drawerModuleContentUsageId,
        drawerContentType: params.drawerContentType,
        drawerContentSystemTaskKind: params.drawerContentSystemTaskKind,
        drawerUsageEntity: params.drawerUsageEntity,
        drawerUsageEntityId: params.drawerUsageEntityId,
        contentTemplate: params.contentTemplate,
        drawerAiPrompt: params.drawerAiPrompt,
        drawerAiReferenceUrl: params.drawerAiReferenceUrl,
        drawerAiReferenceEmbeddingSourceIds: params.drawerAiReferenceEmbeddingSourceIds,
        drawerAiReferenceModuleContentUsageId:
          params.drawerAiReferenceModuleContentUsageId,
        drawerTab: params.drawerTab,
        submissionId: undefined,
        drawerSubmissionsTab: undefined,
        drawerScormCompletionsTab: undefined,
        drawerQuizTab: undefined,
        drawerQuizUseAi: undefined,
        brIds: undefined,
      },
      action
    )
  }

  function handleClearCreationParams() {
    drawer.open(
      {
        u: drawer.params.u,
        drawerTab: drawer.params.drawerTab,
        submissionId: drawer.params.submissionId,
        drawerModuleContentUsageId: undefined,
        drawerContentType: undefined,
        drawerUsageEntity: undefined,
        drawerUsageEntityId: undefined,
        contentTemplate: undefined,
        drawerAiPrompt: undefined,
        drawerAiReferenceUrl: undefined,
        drawerAiReferenceEmbeddingSourceIds: undefined,
        drawerAiReferenceModuleContentUsageId: undefined,
        drawerSubmissionsTab: undefined,
        drawerScormCompletionsTab: undefined,
        drawerQuizTab: undefined,
        brIds: undefined,
      },
      "replace"
    )
  }
}
