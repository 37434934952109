/**
 * @generated SignedSource<<82c1194824f3d059fb4edeb944be7c9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "%future added value";
export type ContentUsageEntity = "content" | "collection" | "curriculum" | "member_onboarding" | "product_app" | "dashboard_block_content" | "organization" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InlineContentDrawerSubmitButtonFragment$data = {
  readonly entity: ContentUsageEntity;
  readonly content: {
    readonly type: ContentType;
    readonly label: string;
    readonly contentLabelId: string | null;
    readonly usages: {
      readonly totalCount: number;
    };
  };
  readonly " $fragmentType": "InlineContentDrawerSubmitButtonFragment";
};
export type InlineContentDrawerSubmitButtonFragment$key = {
  readonly " $data"?: InlineContentDrawerSubmitButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InlineContentDrawerSubmitButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InlineContentDrawerSubmitButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentLabelId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentUsageNodeConnection",
          "kind": "LinkedField",
          "name": "usages",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "a7d3e6113fbda6f3c9e39eed084e3b24";

export default node;
