import AiFormReferencesListItem from "@/content/ai/AiFormReferencesListItem"
import AiReferencesListEmbeddingSourceSelect from "@/content/ai/AiReferencesListEmbeddingSourceSelect"
import { EmbeddingSourceEntity } from "@/content/ai/__generated__/AiReferencesListEmbeddingSourceSelect_PaginationQuery.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import AiBookIcon from "@assets/disco/icons/ai/ai-book.svg"
import AiDocumentTextIcon from "@assets/disco/icons/ai/ai-document-text.svg"
import AiElementIcon from "@assets/disco/icons/ai/ai-element.svg"
import AiLinkIcon from "@assets/disco/icons/ai/ai-link.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoLinkInput, DiscoSwitch } from "@disco-ui"
import useHasEntitlement from "@utils/hook/useHasEntitlement"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"

type AiGenerationReferenceKinds = "url" | EmbeddingSourceEntity

export interface AiFormReferencesListProps extends TestIDProps {
  hideReferenceKinds?: AiGenerationReferenceKinds[]
  referenceUrl: string
  setReferenceUrl: React.Dispatch<React.SetStateAction<string>>
  assetEmbeddingSourceIds: GlobalID[]
  setAssetEmbeddingSourceIds: React.Dispatch<React.SetStateAction<GlobalID[]>>
  contentEmbeddingSourceIds: GlobalID[]
  setContentEmbeddingSourceIds: React.Dispatch<React.SetStateAction<GlobalID[]>>
  trainingDataEmbeddingSourceIds: GlobalID[]
  setTrainingDataEmbeddingSourceIds: React.Dispatch<React.SetStateAction<GlobalID[]>>
  moduleContentUsageId?: GlobalID
  shouldReferenceContentModule: boolean
  setShouldReferenceContentModule: React.Dispatch<React.SetStateAction<boolean>>
}

function AiFormReferencesList({
  testid,
  hideReferenceKinds,
  referenceUrl,
  setReferenceUrl,
  assetEmbeddingSourceIds,
  setAssetEmbeddingSourceIds,
  contentEmbeddingSourceIds,
  setContentEmbeddingSourceIds,
  trainingDataEmbeddingSourceIds,
  setTrainingDataEmbeddingSourceIds,
  moduleContentUsageId,
  shouldReferenceContentModule,
  setShouldReferenceContentModule,
}: AiFormReferencesListProps) {
  const classes = useStyles()
  const hasEntitlement = useHasEntitlement("ai_content_generation")

  /** If they have access to an embedding, they'll be able to select it as a reference */
  const [hideMedia, setHideMedia] = useState<boolean>(false)
  const [hideContent, setHideContent] = useState<boolean>(false)
  const [hideTrainingSource, setHideTrainingSource] = useState<boolean>(false)

  return (
    <div className={classes.container}>
      <AiFormReferencesListItem
        icon={<AiLinkIcon height={16} width={16} />}
        name={"Link"}
        input={
          <DiscoLinkInput
            data-testid={`${testid}.link-input`}
            value={referenceUrl}
            onChange={(val) => setReferenceUrl(val)}
            disabled={!hasEntitlement}
            fullWidth
          />
        }
      />
      {hideReferenceKinds?.includes("training_data") ? null : (
        <AiFormReferencesListItem
          hide={hideMedia}
          icon={<AiElementIcon height={16} width={16} />}
          name={"Media"}
          input={
            <AiReferencesListEmbeddingSourceSelect
              entity={"asset"}
              values={assetEmbeddingSourceIds}
              onSelect={handleAssetSelect}
              onOptionsLoaded={(options) => handleOptionsLoad(options, setHideMedia)}
            />
          }
        />
      )}
      {hideReferenceKinds?.includes("content") ? null : (
        <AiFormReferencesListItem
          hide={hideContent}
          icon={<AiDocumentTextIcon height={16} width={16} />}
          name={"Content"}
          input={
            <AiReferencesListEmbeddingSourceSelect
              entity={"content"}
              values={contentEmbeddingSourceIds}
              onSelect={handleContentSelect}
              onOptionsLoaded={(options) => handleOptionsLoad(options, setHideContent)}
            />
          }
        />
      )}
      {hideReferenceKinds?.includes("training_data") ? null : (
        <AiFormReferencesListItem
          hide={hideTrainingSource}
          icon={<AiBookIcon height={16} width={16} />}
          name={"Training"}
          input={
            <AiReferencesListEmbeddingSourceSelect
              entity={"training_data"}
              values={trainingDataEmbeddingSourceIds}
              onSelect={handleTrainingSourceSelect}
              onOptionsLoaded={(options) =>
                handleOptionsLoad(options, setHideTrainingSource)
              }
            />
          }
        />
      )}
      {moduleContentUsageId && (
        <DiscoSwitch
          className={classes.moduleReferenceSwitch}
          label={"Use module content as reference"}
          checked={shouldReferenceContentModule}
          onChange={handleToggleShouldReferenceContentModule}
        />
      )}
    </div>
  )

  function handleToggleShouldReferenceContentModule(v: boolean) {
    setShouldReferenceContentModule(v)
  }

  function handleOptionsLoad(
    options: any[],
    setHide: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    setHide(!options.length)
  }

  function handleAssetSelect(values: GlobalID[]) {
    setAssetEmbeddingSourceIds(values)
  }

  function handleContentSelect(values: GlobalID[]) {
    setContentEmbeddingSourceIds(values)
  }

  function handleTrainingSourceSelect(values: GlobalID[]) {
    setTrainingDataEmbeddingSourceIds(values)
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "grid",
    gap: theme.spacing(1),
    gridTemplateColumns: "1fr",
  },
  moduleReferenceSwitch: {
    marginTop: theme.spacing(2),
  },
}))

export default AiFormReferencesList
