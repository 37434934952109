/**
 * @generated SignedSource<<b52d38bfcc35bf2592e9acca5a0bc4d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GenerateAiImageStyle = "vivid" | "natural" | "%future added value";
export type GenerateAiImageInput = {
  prompt: string;
  style: GenerateAiImageStyle;
  organizationId: string;
  productId?: string | null;
  createAsset?: boolean | null;
};
export type GenerateContentWithAiProvider_GenerateAiImageCommitMutation$variables = {
  input: GenerateAiImageInput;
};
export type GenerateContentWithAiProvider_GenerateAiImageCommitMutation$data = {
  readonly response: {
    readonly data: string | null;
    readonly node: {
      readonly id: string;
      readonly url: string;
    } | null;
    readonly botResponseId: string | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type GenerateContentWithAiProvider_GenerateAiImageCommitMutation = {
  variables: GenerateContentWithAiProvider_GenerateAiImageCommitMutation$variables;
  response: GenerateContentWithAiProvider_GenerateAiImageCommitMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GenerateAiImageResponse",
    "kind": "LinkedField",
    "name": "generateAiImageInMediaPicker",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "data",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Asset",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "botResponseId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GenerateContentWithAiProvider_GenerateAiImageCommitMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GenerateContentWithAiProvider_GenerateAiImageCommitMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1c2d19a99fa6049c525d7eb6bdd0e096",
    "id": null,
    "metadata": {},
    "name": "GenerateContentWithAiProvider_GenerateAiImageCommitMutation",
    "operationKind": "mutation",
    "text": "mutation GenerateContentWithAiProvider_GenerateAiImageCommitMutation(\n  $input: GenerateAiImageInput!\n) {\n  response: generateAiImageInMediaPicker(input: $input) {\n    data\n    node {\n      id\n      url\n    }\n    botResponseId\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "583be73c19d7b0cfe5175a5252bb6ec2";

export default node;
