import AiFormReferencesList, {
  AiFormReferencesListProps,
} from "@/content/ai/AiFormReferencesList"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AiInput from "@components/ai/AiInput"
import WithEntitlement from "@components/entitlement/WithEntitlement"
import Form from "@components/form/Form"
import { DiscoButton, DiscoFormControl, DiscoSection } from "@disco-ui"
import useHasEntitlement from "@utils/hook/useHasEntitlement"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useState } from "react"

export type GenerateContentWithAiSubmitProps = {
  prompt: string
  referenceUrl?: string
  referenceEmbeddingSourceIds?: GlobalID[]
  referenceModuleContentUsageId?: GlobalID
}

export interface GenerateContentWithAiFormProps extends TestIDProps {
  onSubmit: ({
    prompt,
    referenceUrl,
    referenceEmbeddingSourceIds,
    referenceModuleContentUsageId,
  }: GenerateContentWithAiSubmitProps) => Promise<void> | void
  hideReferenceKinds?: AiFormReferencesListProps["hideReferenceKinds"]
  moduleContentUsageId?: GlobalID
}

function GenerateContentWithAiForm({
  onSubmit,
  testid = "GenerateContentWithAiForm",
  hideReferenceKinds,
  moduleContentUsageId,
}: GenerateContentWithAiFormProps) {
  const hasEntitlement = useHasEntitlement("ai_content_generation")
  const [prompt, setPrompt] = useState<string>("")
  const [referenceUrl, setReferenceUrl] = useState<string>("")
  const [assetEmbeddingSourceIds, setAssetEmbeddingSourceIds] = useState<GlobalID[]>([])
  const [contentEmbeddingSourceIds, setContentEmbeddingSourceIds] = useState<GlobalID[]>(
    []
  )
  const [trainingDataEmbeddingSourceIds, setTrainingDataEmbeddingSourceIds] = useState<
    GlobalID[]
  >([])
  const [shouldReferenceContentModule, setShouldReferenceContentModule] =
    useState<boolean>(false)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const classes = useStyles()

  return (
    <DiscoSection className={classes.container}>
      <Form testid={`${testid}.form`} errorInfo={null}>
        <DiscoFormControl entitlement={"ai_content_generation"}>
          <AiInput
            testid={`${testid}.prompt-input`}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            disabled={!hasEntitlement}
            placeholder={"Describe a prompt for the content you want to create"}
            showBadge={false}
          />
        </DiscoFormControl>
        <DiscoSection groovyDepths={"insideCard"}>
          <DiscoFormControl label={"References"} optional>
            <AiFormReferencesList
              hideReferenceKinds={hideReferenceKinds}
              referenceUrl={referenceUrl}
              setReferenceUrl={setReferenceUrl}
              assetEmbeddingSourceIds={assetEmbeddingSourceIds}
              setAssetEmbeddingSourceIds={setAssetEmbeddingSourceIds}
              contentEmbeddingSourceIds={contentEmbeddingSourceIds}
              setContentEmbeddingSourceIds={setContentEmbeddingSourceIds}
              trainingDataEmbeddingSourceIds={trainingDataEmbeddingSourceIds}
              setTrainingDataEmbeddingSourceIds={setTrainingDataEmbeddingSourceIds}
              moduleContentUsageId={moduleContentUsageId}
              shouldReferenceContentModule={shouldReferenceContentModule}
              setShouldReferenceContentModule={setShouldReferenceContentModule}
            />
          </DiscoFormControl>
        </DiscoSection>
        <div className={classes.buttonContainer}>
          <WithEntitlement entitlement={"ai_image_generation"}>
            {({ hasEntitlement: hasEntitlementInner }) => (
              <DiscoButton
                leftIcon={"stars"}
                onClick={handleSubmit}
                testid={`${testid}.generate`}
                disabled={!hasEntitlementInner}
                className={classes.generateButton}
                shouldDisplaySpinner={isSubmitting}
              >
                {"Generate"}
              </DiscoButton>
            )}
          </WithEntitlement>
        </div>
      </Form>
    </DiscoSection>
  )

  async function handleSubmit() {
    setIsSubmitting(true)
    await onSubmit({
      prompt,
      referenceUrl,
      referenceEmbeddingSourceIds: [
        ...assetEmbeddingSourceIds,
        ...contentEmbeddingSourceIds,
        ...trainingDataEmbeddingSourceIds,
      ],
      referenceModuleContentUsageId: shouldReferenceContentModule
        ? moduleContentUsageId
        : undefined,
    })
    setIsSubmitting(false)
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    background:
      theme.palette.type === "dark"
        ? theme.palette.aiGradient.bluePurpleDark
        : theme.palette.aiGradient.aiLight,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: theme.spacing(2),
  },
  generateButton: {
    background: theme.palette.aiGradient.aiDark,
  },
}))

export default observer(GenerateContentWithAiForm)
