import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"

interface Props {
  icon: React.ReactNode
  name: string
  input: React.ReactNode
  hide?: boolean
}

function AiFormReferencesListItem({ icon, name, input, hide }: Props) {
  const classes = useStyles()

  if (hide === true) return null
  return (
    <div className={classes.container}>
      <div className={classes.lhs}>
        <div className={classes.iconContainer}>{icon}</div>
        <DiscoText variant={"body-sm-600"}>{name}</DiscoText>
      </div>
      <div className={classes.rhs}>{input}</div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    gap: theme.spacing(1.5),
    alignItems: "center",
    minWidth: 0,
    maxWidth: "100%",
  },
  lhs: {
    width: "132px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(0.75),
    padding: theme.spacing(1),
    borderRadius: theme.measure.borderRadius.medium,
    backgroundColor: theme.palette.groovy.neutral[100],
  },
  iconContainer: {
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.measure.borderRadius.default,
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
  },
  rhs: {
    width: "100%",
    minWidth: 0,
    borderRadius: theme.measure.borderRadius.medium,
    backgroundColor: theme.palette.groovy.neutral[100],
  },
}))

export default AiFormReferencesListItem
