import {
  GenerationStatus,
  useGenerateContentWithAi,
} from "@/content/ai/GenerateContentWithAiProvider"
import AICloseIcon from "@assets/disco/icons/ai/ai-close.svg"
import AIStarsIcon from "@assets/disco/icons/ai/ai-stars.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AICheck from "@components/ai/AICheck"
import AiText from "@components/ai/AiText"
import { DiscoAlert, DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"

function GenerateContentWithAiStatuses() {
  const classes = useStyles()

  const {
    titleStatus,
    coverImageStatus,
    descriptionStatus,
    closeStatusPopup,
    showStatusPopup,
  } = useGenerateContentWithAi()

  if (!showStatusPopup) return null

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <AIStarsIcon width={24} height={24} />
        <AiText variant={"body-md-600"}>{"Generating Content"}</AiText>
        <DiscoContainerButton onClick={closeStatusPopup} className={classes.closeButton}>
          <AICloseIcon />
        </DiscoContainerButton>
      </div>
      {[
        { name: "Title", status: titleStatus },
        { name: "Cover Image", status: coverImageStatus },
        { name: "Description", status: descriptionStatus },
      ].map(
        ({ name, status }) =>
          status && (
            <div className={classes.item}>
              {getGeneratingCheckbox(status)}
              <DiscoText>
                <DiscoText variant={"body-md-600"} component={"span"}>
                  {`${name}: `}
                </DiscoText>
                {getGeneratingText(status)}
              </DiscoText>
            </div>
          )
      )}
      <div className={classes.footer}>
        <DiscoAlert
          severity={"default"}
          message={
            "Stay for a moment, it might take a few minutes to generate the content."
          }
          classes={{ root: classes.alert }}
        />
      </div>
    </div>
  )

  function getGeneratingText(generationStatus: GenerationStatus) {
    if (generationStatus === "done") {
      return "Done"
    }
    if (generationStatus === "failed") {
      return "Failed"
    }
    return "Generating..."
  }
  function getGeneratingCheckbox(generationStatus: GenerationStatus) {
    if (generationStatus === "done") {
      return <AICheck isChecked />
    }
    if (generationStatus === "failed") {
      return <AICheck hasError />
    }
    return <AICheck />
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: theme.zIndex.raise2,

    width: "100%",
    maxWidth: "400px",
    background: `${
      theme.palette.type === "dark"
        ? theme.palette.aiGradient.bluePurpleDark
        : theme.palette.aiGradient.aiLight
    } padding-box, ${theme.palette.aiGradient.bluePurple02} border-box`,
    border: "1px solid transparent",
    boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  header: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  closeButton: {
    marginLeft: "auto",
    height: 24,
    width: 24,
  },
  item: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  footer: {
    marginTop: theme.spacing(1),
  },
  alert: {
    background: "transparent",
    padding: 0,
    color: theme.palette.text.secondary,
  },
}))

export default GenerateContentWithAiStatuses
