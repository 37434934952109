import AiApi from "@/common/AiApi"
import { GenerateContentWithAiProvider_GenerateAiImageCommitMutation } from "@/content/ai/__generated__/GenerateContentWithAiProvider_GenerateAiImageCommitMutation.graphql"
import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { GlobalDrawerKind } from "@/core/context/GlobalDrawerProvider"
import RestfulUtil from "@/core/restful/RestfulUtil"
import { GenerateAiImageStyle } from "@/media/generate/__generated__/GenerateImageViewMutation.graphql"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { GlobalID } from "@/relay/RelayTypes"
import { EditorInstance } from "@components/editor/LexicalEditor"
import {
  displayErrorToast,
  displayGraphQLErrorToast,
  displayRestfulErrorToast,
} from "@components/toast/ToastProvider"
import { QueryParamAction, useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { $convertFromMarkdownString, TRANSFORMERS } from "@lexical/markdown"
import { $createParagraphNode, $getSelection } from "lexical"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react"
import { commitMutation, Disposable, graphql } from "react-relay"

interface GenerateContentWithAiProviderProps {
  children: ReactNode
  prompt?: string
  referenceUrl?: string
  referenceEmbeddingSourceIds?: GlobalID[]
  referenceModuleContentUsageId?: GlobalID
}

function GenerateContentWithAiProvider({
  children,
  prompt,
  referenceUrl,
  referenceEmbeddingSourceIds,
  referenceModuleContentUsageId,
}: GenerateContentWithAiProviderProps) {
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()

  const [_, setParams] = useQueryParamState<{ brIds?: string }>()
  const [titleStatus, setTitleStatus] = useState<GenerationStatus>(null)
  const [coverImageStatus, setCoverImageStatus] = useState<GenerationStatus>(null)
  const [descriptionStatus, setDescriptionStatus] = useState<GenerationStatus>(null)
  const [showStatusPopup, setShowStatusPopup] = useState<boolean>(false)
  const [generateAiImageDisposable, setGenerateAiImageDisposable] =
    useState<Disposable | null>(null)

  const keepGenerating = useRef<boolean>(false)
  const brIds = useRef<GlobalID[]>([])

  function generateAiImage(form: ContentFormStore, contentTitle: string) {
    return new Promise((resolve) => {
      const disposable =
        commitMutation<GenerateContentWithAiProvider_GenerateAiImageCommitMutation>(
          RelayEnvironment,
          {
            mutation: graphql`
              mutation GenerateContentWithAiProvider_GenerateAiImageCommitMutation(
                $input: GenerateAiImageInput!
              ) {
                response: generateAiImageInMediaPicker(input: $input) {
                  data
                  node {
                    id
                    url
                  }
                  botResponseId
                  errors {
                    field
                    message
                  }
                }
              }
            `,
            variables: {
              input: {
                organizationId: activeOrganization.id,
                productId: activeProduct?.id,
                style: "vivid" as GenerateAiImageStyle,
                prompt: `Don't include any text unless prompted to. Make me a cover photo for content with the following title: ${contentTitle}`,
                createAsset: true,
              },
            },
            onCompleted: ({ response }, errors) => {
              if (errors || response.errors) {
                const errs = (errors || response.errors)!
                // Failed to create image
                displayGraphQLErrorToast(toJS(errs[0]))
                form.state.content.coverPhoto = null
                setCoverImageStatus("failed")
              } else {
                // Did create image
                const asset = response.node!

                form.state.content.coverPhotoAssetId = asset.id
                form.state.content.coverPhoto = asset.url
                form.state.content.thumbnailAssetId = asset.id
                form.state.content.thumbnailUrl = asset.url
                setCoverImageStatus("done")
                setGenerateAiImageDisposable(null)

                if (response.botResponseId) {
                  handleBotResponseId(response.botResponseId)
                }
              }

              resolve(null)
            },
          }
        )
      setGenerateAiImageDisposable(disposable)
    })
  }

  const titleController = new AbortController()
  const descriptionController = new AbortController()
  const closeStatusPopupTimeout = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    // Once everything is done generating, close the status popup after 5 seconds
    if (
      titleStatus === "done" &&
      coverImageStatus === "done" &&
      descriptionStatus === "done"
    ) {
      const timeout = setTimeout(() => {
        setShowStatusPopup(false)
      }, 5000)

      closeStatusPopupTimeout.current = timeout
    }

    return () => {
      if (closeStatusPopupTimeout.current) {
        clearTimeout(closeStatusPopupTimeout.current)
      }
    }
  }, [titleStatus, coverImageStatus, descriptionStatus])

  const references = {
    referenceUrl,
    referenceEmbeddingSourceIds,
    referenceModuleContentUsageId,
  }

  return (
    <GenerateContentWithAiContext.Provider
      value={{
        prompt,
        generateTitle,
        generateCoverImage,
        generateDescription,
        titleStatus,
        coverImageStatus,
        descriptionStatus,
        stopGenerating,
        showStatusPopup,
        closeStatusPopup,
        keepGenerating: keepGenerating.current,
        handleDrawerMount,
      }}
    >
      {children}
    </GenerateContentWithAiContext.Provider>
  )

  function handleBotResponseId(newBotResponseId: GlobalID) {
    brIds.current.push(newBotResponseId)

    setParams({ brIds: encodeURIComponent(JSON.stringify(brIds.current)) })
  }

  function resetState() {
    keepGenerating.current = false

    setTitleStatus(null)
    setCoverImageStatus(null)
    setDescriptionStatus(null)
    setShowStatusPopup(false)
    setGenerateAiImageDisposable(null)
    brIds.current = []
  }

  function stopGenerating() {
    generateAiImageDisposable?.dispose()
    titleController.abort()
    descriptionController.abort()

    resetState()
  }

  async function generateTitle(form: ContentFormStore) {
    if (!prompt) return ""

    setShowStatusPopup(true)
    setTitleStatus("loading")
    keepGenerating.current = true

    const response = await AiApi.generateText(
      {
        organizationId: activeOrganization.id,
        productId: activeProduct?.id,
        responseType: "title",
        prompt,
        ...references,
      },
      {
        signal: titleController.signal,
      }
    )

    const hasError = RestfulUtil.hasError(response)
    if (hasError) {
      await displayRestfulErrorToast(response)
    }

    let generatedTitle = ""
    if (response.ok && response.body) {
      await RestfulUtil.handleStream(
        response.body,
        (decodedChunk) => {
          if (!keepGenerating.current) return false
          generatedTitle += decodedChunk
          form.state.content.name = generatedTitle
          return true
        },
        {
          onEnd: (data) => {
            handleBotResponseId(data.botResponseId)
          },
        }
      )

      if (!keepGenerating.current) return ""

      if (generatedTitle.trim()) {
        setTitleStatus("done")
      } else {
        displayErrorToast("Unable to generate anything based on the prompt")
        setTitleStatus("failed")
      }
    } else {
      setTitleStatus("failed")
    }
    return generatedTitle
  }

  async function generateCoverImage(form: ContentFormStore, contentTitle: string) {
    if (!contentTitle) return
    setCoverImageStatus("loading")

    await generateAiImage(form, contentTitle)
  }

  async function generateDescription(editor: EditorInstance) {
    if (!prompt) return
    setDescriptionStatus("loading")

    const response = await AiApi.generateText(
      {
        organizationId: activeOrganization.id,
        productId: activeProduct?.id,
        responseType: "description",
        prompt,
        ...references,
      },
      {
        signal: descriptionController.signal,
      }
    )

    const hasError = RestfulUtil.hasError(response)
    if (hasError) {
      await displayRestfulErrorToast(response)
    }

    if (hasError) {
      setDescriptionStatus("failed")
    } else {
      await new Promise((resolve) => {
        // Pipe response into the editor
        editor.focus(() => {
          editor.update(async () => {
            const selection = $getSelection()
            if (!selection) {
              setDescriptionStatus("failed")
              return
            }

            const paragraphNode = $createParagraphNode()
            selection.insertNodes([paragraphNode])

            if (response.ok && response.body) {
              let generatedDescription = ""
              await RestfulUtil.handleStream(
                response.body,
                (decodedChunk) => {
                  if (!keepGenerating.current) return false

                  generatedDescription += decodedChunk
                  editor.update(() => {
                    $convertFromMarkdownString(
                      generatedDescription,
                      TRANSFORMERS,
                      paragraphNode
                    )
                  })
                  return true
                },
                {
                  onEnd: (data) => {
                    handleBotResponseId(data.botResponseId)
                  },
                }
              )

              if (!keepGenerating.current) return

              if (generatedDescription.trim()) {
                setDescriptionStatus("done")
              } else {
                displayErrorToast("Unable to generate anything based on the prompt")
                setDescriptionStatus("failed")
              }
            } else {
              setDescriptionStatus("failed")
            }

            resolve(null)
          })
        })
      })
    }
  }

  async function handleDrawerMount(
    form: ContentFormStore,
    editor: EditorInstance,
    drawer: {
      kind: Extract<GlobalDrawerKind, "contentUsage" | "adminContent">
      setParams: (
        newParams: Partial<{
          drawerAiPrompt?: string
          drawerAiReferenceUrl?: string
          drawerAiReferenceEmbeddingSourceIds?: string
          drawerAiReferenceModuleContentUsageId?: string
          brIds?: string
        }>,
        action?: QueryParamAction | undefined
      ) => void
    }
  ) {
    if (!prompt) return
    form.state.content.isAiGenerated = true

    const title = await generateTitle(form)
    await Promise.all([generateCoverImage(form, title), generateDescription(editor)])

    if (keepGenerating) {
      drawer.setParams({
        drawerAiPrompt: undefined,
        drawerAiReferenceUrl: undefined,
        drawerAiReferenceEmbeddingSourceIds: undefined,
        drawerAiReferenceModuleContentUsageId: undefined,
      })
    }
  }

  function closeStatusPopup() {
    setShowStatusPopup(false)
  }
}

export type GenerationStatus = "loading" | "failed" | "done" | null

type GenerateContentWithAiContextValue = {
  prompt?: string
  generateTitle: (form: ContentFormStore) => Promise<string>
  generateCoverImage: (form: ContentFormStore, contentTitle: string) => Promise<void>
  generateDescription: (editor: EditorInstance) => Promise<void>
  titleStatus: GenerationStatus
  coverImageStatus: GenerationStatus
  descriptionStatus: GenerationStatus
  stopGenerating: () => void
  showStatusPopup: boolean
  closeStatusPopup: VoidFunction
  keepGenerating: boolean
  handleDrawerMount: (
    form: ContentFormStore,
    editor: EditorInstance,
    drawer: any
  ) => Promise<void>
}

const GenerateContentWithAiContext =
  createContext<GenerateContentWithAiContextValue | null>(null)

export const useGenerateContentWithAi = (): GenerateContentWithAiContextValue => {
  const context = useContext(GenerateContentWithAiContext)
  return context!
}

export default observer(GenerateContentWithAiProvider)
