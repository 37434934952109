import { CONTENT_DRAWER_FOOTER_HEIGHT } from "@/content-usage/drawer/footer/InlineContentDrawerFooter"
import { QuizSubmissionEditorFormState } from "@/content-usage/drawer/quizzes/submission-editor/QuizSubmissionEditor"
import { QuizSubmissionEditorFormMutation } from "@/content-usage/drawer/quizzes/submission-editor/__generated__/QuizSubmissionEditorFormMutation.graphql"
import FormStore from "@/core/form/store/FormStore"
import Relay from "@/relay/relayUtils"
import WebFormAnswer from "@/web-form/filler/WebFormAnswer"
import WebFormQuestion from "@/web-form/filler/WebFormQuestion"
import { WEB_FORM_QUESTIONS_MAX_WIDTH } from "@/web-form/utils/webFormConstants"
import { UseWebFormSubmissionQueryReturnType } from "@/web-form/utils/webFormQueryUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import { DiscoButton, DiscoSection, DiscoTooltip } from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import useDisclosure from "@utils/hook/useDisclosure"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useState } from "react"

interface QuizSubmissionFormProps
  extends TestIDProps,
    Pick<UseWebFormSubmissionQueryReturnType, "revision"> {
  form: FormStore<QuizSubmissionEditorFormState, QuizSubmissionEditorFormMutation>
  buttonsContainerRef?: HTMLDivElement | null
  contentLabel: string
  isCompleting: boolean
  handleSubmit: (allowCompletion: boolean) => Promise<{ success: boolean }>
}

function QuizSubmissionForm({
  testid = "QuizSubmissionForm",
  form,
  revision,
  contentLabel,
  buttonsContainerRef,
  isCompleting,
  handleSubmit,
}: QuizSubmissionFormProps) {
  const [direction, setDirection] = useState<"next" | "previous">("next")
  const completionModal = useDisclosure()

  const { webFormRevisionId } = form.state.webFormSubmission!
  const questions = Relay.connectionToArray(revision?.questions)

  const classes = useStyles()
  if (!questions.length) return null
  const currentQuestion = questions[form.state.activeAnswerIndex]
  const currentAnswer = form.state.webFormSubmission.answers[form.state.activeAnswerIndex]
  const currentQuestionNeedsAnswer =
    currentQuestion.isRequired &&
    !currentAnswer?.body &&
    !currentAnswer?.selectedOptions?.length

  return (
    <>
      <Form
        id={`${testid}.${webFormRevisionId}`}
        testid={`${testid}.form`}
        classes={{ formFieldsContainer: classes.container }}
        onSubmit={handleNext}
        buttonsContainerRef={buttonsContainerRef}
        buttons={
          <>
            <DiscoButton
              variant={"outlined"}
              color={"grey"}
              onClick={handlePrevious}
              disabled={form.state.activeAnswerIndex === 0 || form.disabled}
              shouldDisplaySpinner={direction === "previous" && form.isSubmitting}
              testid={`${testid}.previous`}
            >
              {"Previous"}
            </DiscoButton>
            <DiscoTooltip
              content={"You must provide an answer to continue"}
              disabled={!currentQuestionNeedsAnswer}
            >
              <div>
                <Form.SubmitButton
                  id={`${testid}.${webFormRevisionId}`}
                  testid={`${testid}.next`}
                  form={form}
                  {...(isCompleting
                    ? { color: "primary", variant: "contained" }
                    : { color: "grey", variant: "outlined" })}
                  shouldDisplaySpinner={direction === "next" && form.isSubmitting}
                  disabled={form.disabled || currentQuestionNeedsAnswer}
                >
                  {isCompleting ? `Submit ${contentLabel}` : "Next"}
                </Form.SubmitButton>
              </div>
            </DiscoTooltip>
          </>
        }
      >
        <DiscoSection className={classes.question}>
          <WebFormQuestion
            key={currentQuestion.id}
            totalQuestionsCount={questions.length}
            question={currentQuestion}
            answerNode={
              <WebFormAnswer
                question={currentQuestion}
                form={form}
                answerIndex={form.state.activeAnswerIndex}
              />
            }
          />
        </DiscoSection>
      </Form>
      {completionModal.isOpen && (
        <DiscoWarningModal
          testid={"QuizSubmissionFormCompletionConfirmationModal"}
          variant={"primary"}
          modalContentLabel={"unsaved changes"}
          title={`Are you sure you want to submit this ${contentLabel}?`}
          description={`Please review each question carefully before submitting. Your answers are final and you won't have the option to retake the ${contentLabel}.`}
          isOpen
          onClose={completionModal.onClose}
          confirmationButtonProps={{
            onClick: () => handleNext(),
            children: `Submit ${contentLabel}`,
            disabled: form.isSubmitting,
            shouldDisplaySpinner: form.isSubmitting,
          }}
          cancelButtonText={"No, Stay Here"}
        />
      )}
    </>
  )

  async function handlePrevious() {
    setDirection("previous")
    const { success } = await handleSubmit(false)
    if (success) form.state.activeAnswerIndex -= 1
  }

  async function handleNext() {
    setDirection("next")
    // confirm before quiz completion submission
    if (isCompleting && !completionModal.isOpen) return completionModal.onOpen()
    const { success } = await handleSubmit(true)
    if (!success) return
    if (completionModal.isOpen) completionModal.onClose()
    if (!isCompleting) form.state.activeAnswerIndex += 1
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(4),
    paddingBottom: CONTENT_DRAWER_FOOTER_HEIGHT,
  },
  question: {
    width: "100%",
    maxWidth: WEB_FORM_QUESTIONS_MAX_WIDTH,
  },
}))

export default observer(QuizSubmissionForm)
