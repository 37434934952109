import QuizSubmissionIncompleteTile from "@/content-usage/drawer/quizzes/submission-result/QuizSubmissionIncompleteTile"
import { QuizSubmissionResultFilter } from "@/content-usage/drawer/quizzes/utils/quizSubmissionUtils"
import { useDrawerContext } from "@/core/context/DrawerContext"
import { GlobalID } from "@/relay/RelayTypes"
import WebFormResultCheckbox from "@/web-form/result/WebFormResultCheckbox"
import {
  QuizAnswerData,
  QuizQuestionData,
  QuizResult,
} from "@/web-form/utils/webFormQueryUtils"
import { webFormSubmissionsUtils_submissionUserFragment$data } from "@/web-form/utils/__generated__/webFormSubmissionsUtils_submissionUserFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import {
  DiscoButtonSkeleton,
  DiscoDivider,
  DiscoIconButton,
  DiscoSelect,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import DiscoDrawerSidebar from "@disco-ui/drawer/DiscoDrawerSidebar"
import { ArrayUtils, range } from "@utils/array/arrayUtils"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import pluralize from "pluralize"

interface QuizSubmissionResultSidebarProps extends TestIDProps {
  contentLabel: string
  answers: (QuizAnswerData & { question: QuizQuestionData })[]
  currentQuestionId: GlobalID | null
  goToQuestion: (webFormQuestionId: GlobalID) => void
  filter: QuizSubmissionResultFilter
  setFilter: React.Dispatch<React.SetStateAction<QuizSubmissionResultFilter>>
  user?: webFormSubmissionsUtils_submissionUserFragment$data["user"]
  result?: QuizResult
  moreOptions?: React.ReactNode
}

export const QUIZ_SUBMISSION_SIDEBAR_WIDTH = 320

function QuizSubmissionResultSidebar({
  testid = "QuizSubmissionResultSidebar",
  contentLabel = "quiz",
  result,
  answers,
  currentQuestionId,
  goToQuestion,
  filter,
  setFilter,
  /** provide a user if the submission is someone other than the person viewing it */
  user,
  moreOptions,
}: QuizSubmissionResultSidebarProps) {
  const { closeDrawerSidebar } = useDrawerContext()
  const correctAnswers = result?.correctAnswers?.length ?? 0
  const incorrectAnswers = result?.incorrectAnswers?.length ?? 0

  const submitter = user?.fullName ?? "You"

  const isMobile = useIsMobile()
  const classes = useStyles()

  return (
    <DiscoDrawerSidebar testid={testid} classes={{ sidebar: classes.sideBar }}>
      {/* add a placeholder so the DiscoDrawerHeader button doesn't render over the sidebar header here */}
      {isMobile && <div />}
      <div className={classes.header}>
        <div className={classes.headerTitle}>
          <DiscoText
            testid={`${testid}.header-title`}
            variant={"body-sm-500"}
          >{`${contentLabel} Result`}</DiscoText>
          {moreOptions && <div className={classes.headerMoreActions}>{moreOptions}</div>}
        </div>
        {result ? (
          <>
            <DiscoText
              variant={"body-sm"}
              color={"text.secondary"}
            >{`${submitter} completed this ${contentLabel} with a result of:`}</DiscoText>
            <DiscoText
              testid={`${testid}.percentage`}
              variant={"heading-xl"}
              color={"primary.main"}
              align={"center"}
              marginLeft={1.5}
              marginRight={1.5}
              marginTop={1}
              marginBottom={1}
            >{`${result.percentage}%`}</DiscoText>
          </>
        ) : (
          <>
            <DiscoText
              variant={"body-sm"}
              color={"text.secondary"}
            >{`Results will display here when the submission is complete.`}</DiscoText>
            <QuizSubmissionIncompleteTile testid={`${testid}.incomplete-submission`} />
          </>
        )}
      </div>
      <DiscoDivider marginBottom={0} marginTop={0} />
      <ScrollShadowContainer classes={{ scrollContainer: classes.content }}>
        {result && (
          <div className={classes.resultSummaryContainer}>
            <DiscoText variant={"body-sm-500"}>{"Result Summary"}</DiscoText>

            <DiscoText
              variant={"body-sm"}
              color={"text.secondary"}
            >{`${submitter} completed this ${contentLabel} with:`}</DiscoText>
            {!!correctAnswers && (
              <div className={classes.scoreItem}>
                <WebFormResultCheckbox
                  isSelected
                  isCorrectOption
                  variant={"transparent"}
                />
                <DiscoText
                  testid={`${testid}.correct-answers`}
                  variant={"body-sm"}
                  color={"groovy.green.600"}
                >{`${correctAnswers} correct ${pluralize(
                  "answer",
                  correctAnswers
                )}`}</DiscoText>
              </div>
            )}

            {!!incorrectAnswers && (
              <div className={classes.scoreItem}>
                <WebFormResultCheckbox
                  isSelected
                  isCorrectOption={false}
                  variant={"transparent"}
                />
                <DiscoText
                  testid={`${testid}.incorrect-answers`}
                  variant={"body-sm"}
                  color={"groovy.red.600"}
                >{`${incorrectAnswers} incorrect ${pluralize(
                  "answer",
                  incorrectAnswers
                )}`}</DiscoText>
              </div>
            )}
          </div>
        )}

        <DiscoText
          variant={"body-sm"}
          color={"text.secondary"}
          marginTop={2.5}
          marginBottom={2.5}
        >
          {"Click to navigate to a specific question"}
        </DiscoText>

        <div className={classes.answerGrid}>
          {answers.map((a) => (
            <DiscoIconButton
              key={a.id}
              onClick={() => {
                goToQuestion(a.webFormQuestionId)
                if (isMobile) closeDrawerSidebar()
              }}
              className={classNames(classes.answerGridItem, {
                [classes.answerGridItemSelected]:
                  a.webFormQuestionId === currentQuestionId,
              })}
            >
              <DiscoText
                color={
                  a.isCorrect
                    ? "text.primary"
                    : a.isSkipped && !result // if the submission is incomplete, indicate a skipped question with warning yellow
                    ? "groovy.yellow.500"
                    : "text.danger"
                }
              >
                {a.question.ordering + 1}
              </DiscoText>
            </DiscoIconButton>
          ))}
        </div>
      </ScrollShadowContainer>
      <DiscoDivider marginBottom={0} marginTop={0} />
      <div className={classes.footer}>
        <DiscoSelect
          testid={`${testid}.filter`}
          autoWidth
          disableClearable
          autoComplete={false}
          className={classes.select}
          value={filter}
          customSelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            },
          }}
          options={[
            { value: "all", title: "View all questions" },
            ...ArrayUtils.spreadIf(
              [
                {
                  value: "correct" as QuizSubmissionResultFilter,
                  title: "Questions with correct answer",
                },
                {
                  value: "incorrect" as QuizSubmissionResultFilter,
                  title: "Questions with incorrect answer",
                },
              ],
              result
            ),
            ...ArrayUtils.spreadIf(
              [
                {
                  value: "complete" as QuizSubmissionResultFilter,
                  title: "Questions with an answer",
                },
                {
                  value: "incomplete" as QuizSubmissionResultFilter,
                  title: "Questions without an answer",
                },
              ],
              !result
            ),
          ]}
          onChange={(v) => setFilter(v as QuizSubmissionResultFilter)}
        />
      </div>
    </DiscoDrawerSidebar>
  )
}
const useStyles = makeUseStyles((theme) => ({
  sideBar: {
    display: "grid",
    overflow: "hidden",
    height: "100%",
    width: "100%",
    maxWidth: QUIZ_SUBMISSION_SIDEBAR_WIDTH,
    gridTemplateRows: "auto auto 1fr auto auto",
    justifyItems: "center",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      // in mobile, use the full width of the DiscoDrawerHeader and only restrict inner content width
      maxWidth: "unset",
      // button height + top spacing in DiscoDrawerHeader
      gridTemplateRows: `${theme.spacing(3 + 6)}px auto auto 1fr auto auto`,
    },
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    padding: theme.spacing(1.5, 2.5),
    width: "100%",
    maxWidth: QUIZ_SUBMISSION_SIDEBAR_WIDTH,
  },
  headerTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: theme.spacing(1.5),
    flexWrap: "wrap-reverse",
  },
  headerMoreActions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  resultSummaryContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },

  scoreItem: {
    display: "flex",
    gap: theme.spacing(0.5),
    flexWrap: "nowrap",
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1.5, 2.5),
    width: "100%",
    maxWidth: QUIZ_SUBMISSION_SIDEBAR_WIDTH,
  },
  answerGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)",
    gridAutoRows: "1fr",
    gap: theme.spacing(1.5, 1.5),
  },
  answerGridItem: {
    height: "36px",
    width: "36px",
    aspectRatio: 1,
    backgroundColor: theme.palette.groovy.neutral[100],
  },
  answerGridItemSelected: {
    outline: `1.5px solid ${theme.palette.primary.main}`,
  },
  select: {
    width: "100%",
  },
  footer: {
    padding: theme.spacing(1.5, 2.5),
    width: "100%",
    maxWidth: QUIZ_SUBMISSION_SIDEBAR_WIDTH,
  },
}))

function Skeleton() {
  const classes = useStyles()
  return (
    <DiscoDrawerSidebar classes={{ sidebar: classes.sideBar }}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>
          <DiscoTextSkeleton variant={"body-sm-500"} width={"50%"} />
          <DiscoButtonSkeleton width={"40px"} />
        </div>
        <DiscoTextSkeleton variant={"body-sm"} color={"text.secondary"} width={"100%"} />
        <DiscoTextSkeleton
          variant={"heading-xl"}
          color={"primary.main"}
          width={"100px"}
        />
      </div>
      <DiscoDivider marginBottom={0} marginTop={0} />
      <ScrollShadowContainer classes={{ scrollContainer: classes.content }}>
        <div className={classes.resultSummaryContainer}>
          <DiscoText variant={"body-sm-500"}>{"Result Summary"}</DiscoText>

          <DiscoTextSkeleton
            variant={"body-sm"}
            color={"text.secondary"}
            width={"100%"}
          />

          <div className={classes.scoreItem}>
            <WebFormResultCheckbox isSelected isCorrectOption variant={"transparent"} />
            <DiscoTextSkeleton
              variant={"body-sm"}
              color={"groovy.green.600"}
              width={"100%"}
            />
          </div>

          <div className={classes.scoreItem}>
            <WebFormResultCheckbox
              isSelected
              isCorrectOption={false}
              variant={"transparent"}
            />
            <DiscoTextSkeleton
              variant={"body-sm"}
              color={"groovy.red.600"}
              width={"100%"}
            />
          </div>
        </div>

        <DiscoText
          variant={"body-sm"}
          color={"text.secondary"}
          marginTop={2.5}
          marginBottom={2.5}
        >
          {"Click to navigate to a specific question"}
        </DiscoText>

        <div className={classes.answerGrid}>
          {range(10).map((i) => (
            <DiscoButtonSkeleton key={i} />
          ))}
        </div>
      </ScrollShadowContainer>
      <DiscoDivider marginBottom={0} marginTop={0} />
      <div className={classes.footer}>
        <DiscoSelect
          autoWidth
          disableClearable
          autoComplete={false}
          className={classes.select}
          value={"all"}
          options={[{ value: "all", title: "View all questions" }]}
          onChange={() => null}
        />
      </div>
    </DiscoDrawerSidebar>
  )
}

QuizSubmissionResultSidebar.Skeleton = Skeleton
export default QuizSubmissionResultSidebar
