import WebFormQuestion, { WebFormQuestionProps } from "@/web-form/filler/WebFormQuestion"
import WebFormAnswerResult from "@/web-form/result/WebFormAnswerResult"
import { WEB_FORM_QUESTIONS_MAX_WIDTH } from "@/web-form/utils/webFormConstants"
import { QuizAnswerData, QuizQuestionData } from "@/web-form/utils/webFormQueryUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoSection } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useEffect, useRef } from "react"

interface QuizSubmissionResultAnswerListItemProps
  extends TestIDProps,
    Omit<WebFormQuestionProps, "classes" | "question"> {
  scrollTo?: boolean
  answer: QuizAnswerData & { question: QuizQuestionData }
  itemIndex: number
  onBlur: VoidFunction
  showNotAnswered?: boolean
}

function QuizSubmissionResultAnswerListItem({
  testid = "QuizSubmissionResultAnswerListItem",
  itemIndex,
  answer,
  readOnly = false,
  totalQuestionsCount,
  scrollTo,
  onBlur,
  showNotAnswered,
}: QuizSubmissionResultAnswerListItemProps) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollTo) {
      ref.current?.focus({ preventScroll: true }) // prevent scroll so scrollIntoView handles it with smooth behavior
      ref.current?.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }, [scrollTo])

  const classes = useStyles()

  return (
    <DiscoSection
      ref={ref}
      key={answer.id}
      testid={`${testid}.${itemIndex}`}
      padding={3}
      className={classes.container}
      tabIndex={-1}
      onBlur={onBlur}
    >
      <WebFormQuestion
        testid={`${testid}.${itemIndex}.question`}
        readOnly={readOnly}
        totalQuestionsCount={totalQuestionsCount}
        question={answer.question}
        answerNode={
          <WebFormAnswerResult
            testid={`${testid}.${itemIndex}.answer-result`}
            answer={answer}
          />
        }
        showNotAnswered={showNotAnswered}
      />
    </DiscoSection>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: WEB_FORM_QUESTIONS_MAX_WIDTH,
    "&:focus": {
      outline: `1.5px solid ${theme.palette.primary.main}`,
    },
    pointerEvents: "none", // disable pointer events so clicking on the answer doesn't trigger the focus
  },
}))

export default observer(QuizSubmissionResultAnswerListItem)
