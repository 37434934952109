import useUnreadNotifications from "@/apps/util/hooks/useUnreadNotifications"
import { QuizSubmissionsTableRow_submission$key } from "@/content-usage/drawer/quizzes/review-submissions/__generated__/QuizSubmissionsTableRow_submission.graphql"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { GlobalID } from "@/relay/RelayTypes"
import { UserAvatarStackSkeleton } from "@/user/common/avatar-stack/UserAvatarStack"
import UserAvatar from "@/user/common/avatar/UserAvatar"
import useUserTimezone from "@/user/util/useUserTimezone"
import {
  DiscoChip,
  DiscoChipSkeleton,
  DiscoTable,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import { TableCell } from "@material-ui/core"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions, formatElapsedTime } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

interface QuizSubmissionsTableRowProps extends TestIDProps {
  submissionKey: QuizSubmissionsTableRow_submission$key
  showResult: boolean
}

function QuizSubmissionsTableRow({
  testid = "QuizSubmissionsTableRow",
  submissionKey,
  showResult,
}: QuizSubmissionsTableRowProps) {
  const userTimezone = useUserTimezone()
  const drawer = useContentUsageDrawer()

  const submission = useFragment<QuizSubmissionsTableRow_submission$key>(
    graphql`
      fragment QuizSubmissionsTableRow_submission on WebFormSubmission {
        id
        completedAt
        durationSeconds
        result {
          score
          total
          percentage
          correctAnswers {
            id
          }
          incorrectAnswers {
            id
          }
        }
        ...webFormSubmissionsUtils_submissionUserFragment @relay(mask: false)
      }
    `,
    submissionKey
  )

  const notifications = useUnreadNotifications({
    kinds: ["quiz-submitted", "survey-submitted"],
    entityId: submission.id,
  })
  const isUnread = notifications.some((n) => n.webFormSubmission?.id === submission.id)
  const textColor = isUnread ? "text.primary" : "text.secondary"

  const classes = useStyles()
  if (!submission) return null

  return (
    <DiscoTable.Row testid={testid} onClick={() => handleGoToSubmission(submission.id)}>
      <TableCell>
        <div className={classes.user}>
          <UserAvatar
            user={{
              first_name: submission.user.firstName,
              last_name: submission.user.lastName,
              avatar: submission.user.avatar,
              id: submission.user.id,
            }}
            size={32}
          />
          <DiscoText
            testid={`${testid}.user.full-name`}
            variant={isUnread ? "body-sm-600" : "body-sm-500"}
            color={textColor}
          >
            {submission.user.fullName}
          </DiscoText>
          {isUnread && (
            <DiscoChip testid={`${testid}.new`} label={"New"} color={"primary"} />
          )}
        </div>
      </TableCell>
      <TableCell>
        <DiscoChip
          testid={`${testid}.completion-status`}
          label={submission.completedAt ? "Completed" : "Incomplete"}
          color={submission.completedAt ? "green" : "grey"}
          textVariant={isUnread ? "body-xs-600" : "body-xs"}
        />
      </TableCell>
      {showResult && (
        <TableCell>
          {submission.result ? (
            <DiscoText
              testid={`${testid}.percentage`}
              variant={isUnread ? "body-sm-600" : "body-sm"}
              color={textColor}
            >{`${submission.result.percentage}%`}</DiscoText>
          ) : null}
        </TableCell>
      )}
      <TableCell>
        {submission.durationSeconds && (
          <DiscoText
            testid={`${testid}.duration`}
            variant={isUnread ? "body-sm-600" : "body-sm"}
            color={textColor}
          >
            {formatElapsedTime(submission.durationSeconds)}
          </DiscoText>
        )}
      </TableCell>
      <TableCell>
        {submission.completedAt ? (
          <DiscoText variant={isUnread ? "body-sm-600" : "body-sm"} color={textColor}>
            {formatDateWithOptions({
              timeZone: userTimezone,
              format: DATE_FORMAT.DEFAULT_DATE_WITH_SHORT_TIME_FORMAT_AT,
            })(new Date(submission.completedAt))}
          </DiscoText>
        ) : null}
      </TableCell>
    </DiscoTable.Row>
  )

  function handleGoToSubmission(id: GlobalID) {
    drawer.setParams({ drawerQuizTab: "submissions-detail", submissionId: id })
  }
}

function Skeleton() {
  const classes = useStyles()
  return (
    <DiscoTable.Row>
      <TableCell>
        <div className={classes.user}>
          <UserAvatarStackSkeleton stackSize={1} avatarSize={32} />
          <DiscoTextSkeleton width={"80%"} />
        </div>
      </TableCell>
      <TableCell>
        <DiscoChipSkeleton color={"grey"} />
      </TableCell>
      <TableCell>
        <DiscoTextSkeleton width={"80%"} />
      </TableCell>
      <TableCell>
        <DiscoTextSkeleton width={"60%"} />
      </TableCell>
    </DiscoTable.Row>
  )
}

const useStyles = makeUseStyles((theme) => ({
  user: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
}))

QuizSubmissionsTableRow.Skeleton = Skeleton
export default QuizSubmissionsTableRow
