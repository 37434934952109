import ContentCTABannerTemplate, {
  ContentCTABannerTemplateSkeleton,
} from "@/content-usage/drawer/quizzes/ContentCTABannerTemplate"
import useQuizEditorDrawer from "@/content-usage/drawer/quizzes/editor/useQuizEditorDrawer"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import useUserTimezone from "@/user/util/useUserTimezone"
import { useWebFormSubmissionQuery } from "@/web-form/utils/webFormQueryUtils"
import { DiscoButton, DiscoText } from "@disco-ui"
import { usePermissionsFragment$key } from "@utils/hook/__generated__/usePermissionsFragment.graphql"
import usePermissions from "@utils/hook/usePermissions"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"

interface QuizContentBannerProps extends TestIDProps {
  contentUsageKey: usePermissionsFragment$key | null
  revisionId: GlobalID
  contentUsageId: GlobalID
  contentLabel: string
}

function QuizContentBanner({
  testid = "QuizContentBanner",
  contentUsageKey,
  revisionId,
  contentUsageId,
  contentLabel,
}: QuizContentBannerProps) {
  const { goToQuestions } = useQuizEditorDrawer()
  const permissions = usePermissions(contentUsageKey)
  const hasReadPermissions = permissions.has("content.read")
  const userTimezone = useUserTimezone()

  const { submission, result } = useWebFormSubmissionQuery({
    revisionId, // revision ID must be provided so learner can see their submission
    usageId: contentUsageId,
    include: hasReadPermissions,
  })

  if (!hasReadPermissions) return null

  return (
    <ContentCTABannerTemplate
      testid={testid}
      message={renderMessage()}
      buttons={renderButtons()}
    />
  )

  function renderMessage() {
    const titleTestId = `${testid}.title`
    const bodyTestId = `${testid}.body`
    if (!submission)
      return (
        <>
          <DiscoText
            testid={`${titleTestId}.start`}
            variant={"body-md-600"}
          >{`Start ${contentLabel} 🎯`}</DiscoText>
          <DiscoText testid={`${bodyTestId}.start`}>
            {`You haven't started the ${contentLabel} yet, click the button to get started!`}
          </DiscoText>
        </>
      )

    if (submission.completedAt)
      return (
        <>
          {result && (
            <>
              <DiscoText
                testid={`${titleTestId}.result`}
                variant={"heading-xl"}
                color={"primary.main"}
              >
                {`${result.percentage}%`}
              </DiscoText>
              <DiscoText
                testid={`${bodyTestId}.result`}
                variant={"body-md-600"}
              >{`You completed this ${contentLabel} with a score of ${result.percentage}%`}</DiscoText>
            </>
          )}
          <DiscoText
            variant={"body-sm-500"}
          >{`You completed this ${contentLabel} on ${formatDateWithOptions({
            timeZone: userTimezone,
          })(new Date(submission.completedAt))}!`}</DiscoText>
        </>
      )
    return (
      <>
        <DiscoText
          testid={`${titleTestId}.resume`}
          variant={"body-md-600"}
        >{`📌 Resume ${contentLabel}`}</DiscoText>
        <DiscoText testid={`${bodyTestId}.start`}>
          {"Continue where you left off"}
        </DiscoText>
      </>
    )
  }

  function renderButtons() {
    return (
      <DiscoButton
        testid={`${testid}.go-to-questions`}
        onClick={goToQuestions}
        color={"grey"}
        variant={"outlined"}
      >
        {submission?.completedAt
          ? `View ${result ? "Results" : "Response"}`
          : submission
          ? `Resume ${contentLabel}`
          : `Start ${contentLabel}`}
      </DiscoButton>
    )
  }
}

export default Relay.withSkeleton({
  skeleton: () => <ContentCTABannerTemplateSkeleton />,
  component: QuizContentBanner,
})
