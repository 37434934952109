import useUnreadNotifications from "@/apps/util/hooks/useUnreadNotifications"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { UserAvatarStackSkeleton } from "@/user/common/avatar-stack/UserAvatarStack"
import UserAvatar from "@/user/common/avatar/UserAvatar"
import useUserTimezone from "@/user/util/useUserTimezone"
import DeleteWebFormSubmissionButton from "@/web-form/submission/DeleteWebFormSubmissionButton"
import { QuizRevision } from "@/web-form/utils/webFormQueryUtils"
import { WebFormSubmissionsUtils } from "@/web-form/utils/webFormSubmissionsUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoChip, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { ListItem } from "@material-ui/core"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import pluralize from "pluralize"

interface QuizSubmissionsDetailSubmissionsListSidebarItemProps extends TestIDProps {
  submission: WebFormSubmissionsUtils.WebFormSubmissionData
  revision: QuizRevision
}

function QuizSubmissionsDetailSubmissionsListSidebarItem({
  testid = "QuizSubmissionsDetailSubmissionsListSidebarItem",
  submission,
  revision,
}: QuizSubmissionsDetailSubmissionsListSidebarItemProps) {
  const userTimezone = useUserTimezone()
  const drawer = useContentUsageDrawer()

  const notifications = useUnreadNotifications({
    kinds: ["quiz-submitted", "survey-submitted"],
    entityId: submission.id,
  })
  const isUnread = notifications.some((n) => n.webFormSubmission?.id === submission.id)

  const isSelected = drawer.params.submissionId === submission.id
  const answers = Relay.connectionToArray(submission.answers)
  const questions = Relay.connectionToArray(revision?.questions)
  const classes = useStyles()

  return (
    <ListItem
      button
      data-testid={`${testid}.container`}
      onClick={() => handleGoToSubmission(submission.id)}
      selected={isSelected}
      classes={{ root: classes.root }}
    >
      <UserAvatar
        user={{
          first_name: submission.user.firstName,
          last_name: submission.user.lastName,
          avatar: submission.user.avatar,
          id: submission.user.id,
        }}
        size={32}
        className={classes.avatar}
        placeholderClassName={classes.avatar}
      />
      <div className={classes.text}>
        <DiscoText
          color={isSelected ? "primary.contrastText" : "text.primary"}
          variant={isUnread ? "body-sm-600" : "body-sm-500"}
          truncateText={1}
        >
          {submission.user.fullName}
        </DiscoText>

        <DiscoText
          color={
            isSelected
              ? "primary.contrastText"
              : submission.completedAt
              ? "text.secondary"
              : "groovy.yellow.500"
          }
          variant={
            isUnread || (!submission.completedAt && answers.length)
              ? "body-xs-600"
              : "body-xs"
          }
        >
          {submission.completedAt
            ? `Completed on ${formatDateWithOptions({
                timeZone: userTimezone,
              })(new Date(submission.completedAt))}`
            : answers.length
            ? `${answers.length}/${questions.length} ${pluralize(
                "question",
                questions.length
              )} completed`
            : "Not completed"}
        </DiscoText>
      </div>
      {isUnread ? (
        <DiscoChip label={"New"} color={"primary"} classes={{ root: classes.chip }} />
      ) : isSelected ? (
        <DiscoMoreActionsDropdown
          key={`${testid}-more-actions`}
          testid={`${testid}.more-actions`}
          rotateIcon
          width={36}
          height={36}
        >
          <DeleteWebFormSubmissionButton submissionId={submission.id}>
            {(props) => (
              <DiscoDropdownItem
                title={"Delete"}
                testid={`${testid}.actions-delete`}
                {...props}
              />
            )}
          </DeleteWebFormSubmissionButton>
        </DiscoMoreActionsDropdown>
      ) : null}
    </ListItem>
  )

  function handleGoToSubmission(id: GlobalID) {
    drawer.setParams({ drawerQuizTab: "submissions-detail", submissionId: id })
  }
}

function Skeleton() {
  const classes = useStyles()
  return (
    <ListItem button classes={{ root: classes.root }}>
      <UserAvatarStackSkeleton avatarSize={32} stackSize={1} />
      <div className={classes.text}>
        <DiscoTextSkeleton variant={"body-sm-500"} width={"100%"} />
        <DiscoTextSkeleton variant={"body-xs"} width={"100%"} />
      </div>
    </ListItem>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
    minWidth: 0,

    padding: theme.spacing(1.5),
    borderRadius: theme.measure.borderRadius.medium,

    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  text: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minWidth: 0,
  },
  avatar: {
    flex: "1 0 auto",
  },
  chip: {
    flexShrink: 0,
  },
}))

QuizSubmissionsDetailSubmissionsListSidebarItem.Skeleton = Skeleton
export default QuizSubmissionsDetailSubmissionsListSidebarItem
