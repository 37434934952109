/**
 * @generated SignedSource<<2efbd36a103da9732cbc8f1fc18f64eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuizSubmissionsTableRow_submission$data = {
  readonly id: string;
  readonly completedAt: string | null;
  readonly durationSeconds: number | null;
  readonly result: {
    readonly score: number;
    readonly total: number;
    readonly percentage: number;
    readonly correctAnswers: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly incorrectAnswers: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null;
  readonly user: {
    readonly id: string;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly fullName: string;
    readonly avatar: string;
  };
  readonly " $fragmentType": "QuizSubmissionsTableRow_submission";
};
export type QuizSubmissionsTableRow_submission$key = {
  readonly " $data"?: QuizSubmissionsTableRow_submission$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuizSubmissionsTableRow_submission">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuizSubmissionsTableRow_submission",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "durationSeconds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WebFormSubmissionResult",
      "kind": "LinkedField",
      "name": "result",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "score",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "percentage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WebFormAnswer",
          "kind": "LinkedField",
          "name": "correctAnswers",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WebFormAnswer",
          "kind": "LinkedField",
          "name": "incorrectAnswers",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatar",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WebFormSubmission",
  "abstractKey": null
};
})();

(node as any).hash = "51fd28c7d628dfef1f9afd0f975d9f1a";

export default node;
