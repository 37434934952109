import GenerateContentWithAiForm, {
  GenerateContentWithAiFormProps,
  GenerateContentWithAiSubmitProps,
} from "@/content/ai/GenerateContentWithAiForm"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displayErrorToast } from "@components/toast/ToastProvider"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface GenerateContentWithAiProps extends TestIDProps {
  onSubmit: GenerateContentWithAiFormProps["onSubmit"]
  moduleContentUsageId?: GlobalID
}

function GenerateContentWithAi({
  onSubmit,
  moduleContentUsageId,
}: GenerateContentWithAiProps) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <GenerateContentWithAiForm
        onSubmit={handleSubmit}
        moduleContentUsageId={moduleContentUsageId}
      />
    </div>
  )

  async function handleSubmit(context: GenerateContentWithAiSubmitProps) {
    if (context.prompt.length < 20) {
      displayErrorToast("Prompt should be a minimum of 20 characters")
    } else {
      await onSubmit(context)
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
}))

export default observer(GenerateContentWithAi)
