import QuizSubmissionEditorFooter from "@/content-usage/drawer/quizzes/submission-editor/QuizSubmissionEditorFooter"
import QuizSubmissionEditorHeader from "@/content-usage/drawer/quizzes/submission-editor/QuizSubmissionEditorHeader"
import QuizSubmissionForm from "@/content-usage/drawer/quizzes/submission-editor/QuizSubmissionForm"
import { QuizSubmissionEditorFormMutation } from "@/content-usage/drawer/quizzes/submission-editor/__generated__/QuizSubmissionEditorFormMutation.graphql"
import QuizSubmissionResult from "@/content-usage/drawer/quizzes/submission-result/QuizSubmissionResult"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import { useFormStore } from "@/core/form/store/FormStore"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import {
  WebFormAnswerInput,
  useStartWebFormSubmission,
} from "@/web-form/utils/webFormFillerUtils"
import {
  getQuizSubmissionInput,
  useWebFormSubmissionQuery,
} from "@/web-form/utils/webFormQueryUtils"
import { WebFormSubmissionsUtils } from "@/web-form/utils/webFormSubmissionsUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react"
import { commitLocalUpdate, graphql } from "relay-runtime"

interface QuizSubmissionEditorProps extends TestIDProps {
  revisionId: GlobalID
  usageId: GlobalID
  title?: string | null
  contentLabel: string
}

export type CreateWebFormAnswersInput = {
  webFormRevisionId: GlobalID
  answers: WebFormAnswerInput[]
  contentUsageId: GlobalID
}

export type QuizSubmissionEditorFormState = {
  webFormSubmission: CreateWebFormAnswersInput
  activeAnswerIndex: number
  submissionConnectionId?: string | null
}

function QuizSubmissionEditor({
  testid = "QuizSubmissionEditor",
  revisionId,
  usageId,
  title,
  contentLabel,
}: QuizSubmissionEditorProps) {
  const usageDrawer = useContentUsageDrawer()
  const [buttonsContainerRef, setButtonsContainerRef] = useState<HTMLDivElement | null>(
    null
  )
  const isGoingBack = useRef(false)

  const { submission, revision, result } = useWebFormSubmissionQuery({
    revisionId,
    usageId,
  })

  // If a submission hasn't been started yet, create one so the submission duration will
  // be tracked starting from the moment the first question is visible
  const startSubmission = useStartWebFormSubmission({ revisionId, usageId })
  useEffect(() => {
    if (submission?.id) return
    startSubmission()
  }, [submission?.id, startSubmission])

  const questions = Relay.connectionToArray(revision?.questions)
  const submissionInput = getQuizSubmissionInput(revision, submission)
  const firstUnansweredIndex = submissionInput.answers.findIndex(
    (a) => a.isSkipped !== false
  )

  const form = useFormStore<
    QuizSubmissionEditorFormMutation,
    QuizSubmissionEditorFormState
  >(
    graphql`
      mutation QuizSubmissionEditorFormMutation($input: CreateWebFormAnswerInput!) {
        response: createWebFormAnswer(input: $input) {
          node {
            ...webFormQueryUtils_submissionFragment @relay(mask: false)
            contentUsage {
              ...ContentUsageUtils_ContentCompletionFragment
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      /**
       * prefer to go to the first unanswered question,
       * go to the last question if there's a submission (all questions answered),
       * or the first if not
       */
      activeAnswerIndex:
        firstUnansweredIndex > -1
          ? firstUnansweredIndex
          : submission
          ? questions.length - 1
          : 0,
      webFormSubmission: {
        contentUsageId: usageId,
        ...submissionInput,
      },
    },
    { requireChangeToSubmit: false }
  )
  const isComplete = !!submission?.completedAt
  const modal = useInitUnsavedChangesModalContext()

  const { selectedOptions: initialOptions } =
    form.initialState.webFormSubmission?.answers[form.state.activeAnswerIndex] ?? {}
  const { selectedOptions } =
    form.state.webFormSubmission?.answers[form.state.activeAnswerIndex] ?? {}

  useEffect(() => {
    const { added, removed } = ArrayUtils.diff(
      initialOptions ?? [],
      selectedOptions ?? []
    )
    modal.setUnsavedChanges(Boolean(added.length || removed.length))
  }, [form.state.activeAnswerIndex, initialOptions, selectedOptions, modal])

  const isCompleting = form.state.activeAnswerIndex === questions.length - 1

  const classes = useStyles()

  return (
    <>
      <QuizSubmissionEditorHeader
        testid={testid}
        title={title ?? "Untitled"}
        overlineTitle={contentLabel}
        onClose={handleClose}
        onBack={handleBack}
        totalQuestions={isComplete ? undefined : questions.length}
        activeAnswerIndex={isComplete ? undefined : form.state.activeAnswerIndex}
        shouldDisplayBackSpinner={form.isSubmitting && isGoingBack.current}
        showResultSummaryButton={Boolean(submission?.hasAnswerKey)}
      />
      {isComplete ? (
        <QuizSubmissionResult
          result={result}
          submission={submission}
          revision={revision}
          contentLabel={contentLabel}
        />
      ) : (
        <UnsavedChangesModalProvider {...modal}>
          <ScrollShadowContainer
            classes={{ parentContainer: classes.quizQuestionsBackground }}
          >
            <QuizSubmissionForm
              form={form}
              revision={revision}
              contentLabel={contentLabel}
              buttonsContainerRef={buttonsContainerRef}
              isCompleting={isCompleting}
              handleSubmit={handleSubmit}
            />
          </ScrollShadowContainer>
        </UnsavedChangesModalProvider>
      )}
      {!isComplete && (
        <QuizSubmissionEditorFooter ref={(ref) => setButtonsContainerRef(ref)} />
      )}
    </>
  )

  async function handleSubmit(allowCompletion = true) {
    const answer = form.state.webFormSubmission.answers[form.state.activeAnswerIndex]
    const contentUsageId = form.state.webFormSubmission.contentUsageId!

    // If going back and no answer is entered, skip saving so required questions don't fail validation
    if (!allowCompletion && !answer.body && !answer.selectedOptions?.length)
      return { success: true }

    const { didSave, response } = await form.submit({
      contentUsageId,
      webFormQuestionId: answer.webFormQuestionId,
      answer: {
        ...answer,
        isSkipped: false, // we can pass false here since we want the mutation to validate if the answer is skipped
      },
      isCompleting: isCompleting && allowCompletion,
    })

    if (!didSave) return { success: false }

    // update the submission input with the latest answers
    form.state.webFormSubmission = {
      contentUsageId,
      ...getQuizSubmissionInput(revision, response?.node),
    }

    // if the submission isn't already linked to the revision, add it
    commitLocalUpdate(RelayEnvironment, (store) => {
      if (!response?.node) return
      const submissionNode = store.get(response.node.id)
      if (!submissionNode) return
      const revisionNode = store.get(revisionId)
      if (!revisionNode) return
      const viewerSubRecord = revisionNode.getLinkedRecord("viewerSubmission", {
        contentUsageId,
      })
      // If the submission is already linked there's nothing to do
      if (viewerSubRecord?.getDataID() === submissionNode.getDataID()) return
      revisionNode.setLinkedRecord(submissionNode, "viewerSubmission", { contentUsageId })
    })

    // Invalidate the submissions count so if an admin submits the form it will immediately
    // show that submissions exist for this usage
    if (response?.node)
      WebFormSubmissionsUtils.invalidateWebForm({ webFormId: response.node.webFormId })

    return { success: didSave }
  }

  function handleClose() {
    modal.handleLeave({
      onLeave: usageDrawer.close,
    })
  }

  async function handleBack() {
    if (submission?.completedAt) return leaveQuestions()
    isGoingBack.current = true
    // save the answer before leaving, but don't allow completion
    const { success } = await handleSubmit(false)
    isGoingBack.current = false
    if (success) return leaveQuestions()
    // if for some reason the answer couldn't be saved, ask if the user wants to leave (without saving)
    modal.handleLeave({
      onLeave: leaveQuestions,
    })
  }

  function leaveQuestions() {
    usageDrawer.setParams({ drawerQuizTab: undefined })
  }
}

const useStyles = makeUseStyles((theme) => ({
  quizQuestionsBackground: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}))

export default observer(QuizSubmissionEditor)
