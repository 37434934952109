import { QuizSubmissionsDrawerContentProps } from "@/content-usage/drawer/ContentUsageQuizSubmissionsDrawerContent"
import QuizSubmissionsExportCsvButton from "@/content-usage/drawer/quizzes/review-submissions/QuizSubmissionsExportCsvButton"
import QuizSubmissionsFilterOptions from "@/content-usage/drawer/quizzes/review-submissions/QuizSubmissionsFilterOptions"
import QuizSubmissionsOrderOptions from "@/content-usage/drawer/quizzes/review-submissions/QuizSubmissionsOrderOptions"
import QuizSubmissionsTableFilterTags from "@/content-usage/drawer/quizzes/review-submissions/QuizSubmissionsTableFilterTags"
import QuizSubmissionsTableRow from "@/content-usage/drawer/quizzes/review-submissions/QuizSubmissionsTableRow"
import {
  QuizSubmissionsTableQuery,
  QuizSubmissionsTableQuery$variables,
} from "@/content-usage/drawer/quizzes/review-submissions/__generated__/QuizSubmissionsTableQuery.graphql"
import { QuizSubmissionsTable_PaginationFragment$key } from "@/content-usage/drawer/quizzes/review-submissions/__generated__/QuizSubmissionsTable_PaginationFragment.graphql"
import { ContentType } from "@/content-usage/drawer/__generated__/InlineContentDrawerTemplateFragment.graphql"
import DropdownIcon from "@/core/ui/iconsax/bold/arrow-down.svg"
import Relay from "@/relay/relayUtils"
import { WebFormSubmissionsUtils } from "@/web-form/utils/webFormSubmissionsUtils"
import {
  DiscoButton,
  DiscoDropdown,
  DiscoSection,
  DiscoTable,
  DiscoTableSkeletonWithHeader,
} from "@disco-ui"
import DiscoTableSearchInput, {
  DiscoTableSearchInputSkeleton,
} from "@disco-ui/table/header/search/DiscoTableSearchInput"
import { useTheme } from "@material-ui/core"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"
import { useEffect, useMemo } from "react"
import { graphql, useLazyLoadQuery, usePaginationFragment } from "react-relay"

const SUBMISSIONS_PER_PAGE = 10

type Props = TestIDProps &
  QuizSubmissionsDrawerContentProps & {
    contentType: ContentType
  }

function QuizSubmissionsTable({
  testid = "QuizSubmissionsTable",
  contentUsageId,
  revision,
  contentType,
}: Props) {
  const {
    activePage,
    setActivePage,
    toolbarState,
    handleSearchChange,
    handleOrderBy,
    handleFilterBy,
  } = WebFormSubmissionsUtils.useToolbarState()

  const { node } = useLazyLoadQuery<QuizSubmissionsTableQuery>(
    graphql`
      query QuizSubmissionsTableQuery(
        $id: ID!
        $contentUsageId: ID
        $orderBy: WebFormSubmissionsOrderByInput
        $search: String
        $isComplete: Boolean
        $completedAfter: DateTime
        $percentageGreaterThanOrEqual: Float
        $percentageLessThan: Float
        $first: Int
        $after: String
        $last: Int
        $before: String
      ) {
        node(id: $id) {
          ... on WebFormRevision {
            id
            ...QuizSubmissionsTable_PaginationFragment
              @arguments(
                contentUsageId: $contentUsageId

                orderBy: $orderBy
                search: $search
                isComplete: $isComplete
                completedAfter: $completedAfter
                percentageGreaterThanOrEqual: $percentageGreaterThanOrEqual
                percentageLessThan: $percentageLessThan

                first: $first
                after: $after
                last: $last
                before: $before
              )
          }
        }
      }
    `,
    {
      id: revision.id,
      contentUsageId,
      first: SUBMISSIONS_PER_PAGE,
    }
  )

  const { data, refetch } = usePaginationFragment<
    QuizSubmissionsTableQuery,
    QuizSubmissionsTable_PaginationFragment$key
  >(
    graphql`
      fragment QuizSubmissionsTable_PaginationFragment on WebFormRevision
      @refetchable(queryName: "QuizSubmissionsTablePaginationQuery")
      @argumentDefinitions(
        contentUsageId: { type: "ID" }

        orderBy: { type: "WebFormSubmissionsOrderByInput" }
        search: { type: "String" }
        isComplete: { type: "Boolean" }
        completedAfter: { type: "DateTime" }
        percentageGreaterThanOrEqual: { type: "Float" }
        percentageLessThan: { type: "Float" }

        first: { type: "Int" }
        after: { type: "String" }
        last: { type: "Int" }
        before: { type: "String" }
      ) {
        id
        submissions(
          contentUsageId: $contentUsageId

          orderBy: $orderBy
          search: $search
          isComplete: $isComplete
          completedAfter: $completedAfter
          percentageGreaterThanOrEqual: $percentageGreaterThanOrEqual
          percentageLessThan: $percentageLessThan

          first: $first
          after: $after
          last: $last
          before: $before
        )
          @connection(
            key: "QuizSubmissionsTable_submissions"
            filters: [
              "orderBy"
              "search"
              "isComplete"
              "completedAfter"
              "percentageGreaterThanOrEqual"
              "percentageLessThan"
            ]
          ) {
          __id
          totalCount
          edges {
            cursor
            node {
              id
              ...QuizSubmissionsTableRow_submission
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    node
  )
  const submissions = Relay.connectionToArray(data?.submissions)
  useEffect(() => {
    // if the total count changes, invalidate the average score and total count records
    WebFormSubmissionsUtils.invalidateWebForm({
      webFormId: revision.webFormId,
    })
  }, [data?.submissions.totalCount, revision.webFormId])

  const queryVars = useMemo<Partial<QuizSubmissionsTableQuery$variables>>(
    () => ({
      id: revision.id,
      contentUsageId,
      first: SUBMISSIONS_PER_PAGE,
      orderBy: toolbarState.orderBy && {
        field: toolbarState.orderBy.field,
        direction: toolbarState.orderBy.direction,
      },
      search: toolbarState.search,
      ...WebFormSubmissionsUtils.handleFilterByFields(toolbarState),
    }),
    [revision.id, contentUsageId, toolbarState]
  )

  useEffect(() => {
    refetch(queryVars, {
      fetchPolicy: "store-and-network",
    })
  }, [queryVars, refetch])

  const theme = useTheme()

  if (!data) return null

  const showResult = contentType !== "survey"

  return (
    <DiscoTable
      testid={testid}
      activePage={activePage}
      setActivePage={setActivePage}
      rowsPerPage={SUBMISSIONS_PER_PAGE}
      filterDeps={[toolbarState]}
      header={getHeaders(showResult)}
      rows={submissions.map((submission, index) => (
        <QuizSubmissionsTableRow
          key={submission.id}
          submissionKey={submission}
          testid={`${testid}.submission-${index}`}
          showResult={showResult}
        />
      ))}
      connection={{
        cursorsList: data.submissions.edges.map((e: any) => e.cursor),
        totalCount: data.submissions.totalCount,
        pageInfo: {
          endCursor: data.submissions.pageInfo.endCursor,
          startCursor: data.submissions.pageInfo.startCursor,
        },
      }}
      onPaginate={refetch}
      sectionProps={{ groovyDepths: "insideCard" }}
      headerButtons={
        <>
          <DiscoTableSearchInput testid={testid} onChange={handleSearchChange} />

          <DiscoDropdown
            testid={`${testid}.sort.select`}
            menuButton={({ onClick }) => (
              <DiscoButton
                rightIcon={<DropdownIcon fill={theme.palette.groovy.grey[700]} />}
                color={"grey"}
                variant={"outlined"}
                onClick={onClick}
              >
                {toolbarState.orderBy?.title ?? "Sort"}
              </DiscoButton>
            )}
          >
            <QuizSubmissionsOrderOptions
              testid={testid}
              toolbarState={toolbarState}
              handleOrderBy={handleOrderBy}
              showResult={showResult}
            />
          </DiscoDropdown>

          <DiscoDropdown
            testid={`${testid}.filter.select`}
            menuButton={({ onClick }) => (
              <DiscoButton
                rightIcon={<DropdownIcon fill={theme.palette.groovy.grey[700]} />}
                color={"grey"}
                variant={"outlined"}
                onClick={onClick}
              >
                {"Filters"}
              </DiscoButton>
            )}
          >
            <QuizSubmissionsFilterOptions
              testid={testid}
              toolbarState={toolbarState}
              handleFilterBy={handleFilterBy}
              showResult={showResult}
            />
          </DiscoDropdown>
          <QuizSubmissionsExportCsvButton
            webFormRevisionId={revision.id}
            queryVariables={queryVars}
            isDisabled={!submissions.length}
          />
        </>
      }
      headerFilterTags={<QuizSubmissionsTableFilterTags />}
    />
  )
}

function getHeaders(showResult: boolean) {
  return [
    { value: "Name" },
    { value: "Status" },
    ...ArrayUtils.spreadIf({ value: "Result" }, showResult),
    { value: "Time Spent" },
    { value: "Completed On" },
  ]
}

export function QuizSubmissionsTableSkeleton(props: Pick<Props, "contentType">) {
  const theme = useTheme()
  return (
    <DiscoSection groovyDepths={"insideCard"}>
      <DiscoTableSkeletonWithHeader
        header={getHeaders(props.contentType !== "survey")}
        headerButtons={
          <>
            <DiscoTableSearchInputSkeleton />
            <DiscoButton
              rightIcon={<DropdownIcon fill={theme.palette.groovy.grey[700]} />}
              color={"grey"}
              variant={"outlined"}
            >
              {"Sort"}
            </DiscoButton>
            <DiscoButton
              rightIcon={<DropdownIcon fill={theme.palette.groovy.grey[700]} />}
              color={"grey"}
              variant={"outlined"}
            >
              {"Filters"}
            </DiscoButton>
          </>
        }
        row={<QuizSubmissionsTableRow.Skeleton />}
      />
    </DiscoSection>
  )
}

export default Relay.withSkeleton({
  component: QuizSubmissionsTable,
  skeleton: QuizSubmissionsTableSkeleton,
})
