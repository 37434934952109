/**
 * @generated SignedSource<<9572e14d6c70d8d5b810d33bc80b1e24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentSystemTaskKind = "slack_connection" | "calendar_connection" | "custom_profile_field" | "community_agreement" | "default_profile_fields" | "onboarding_complete" | "onboarding_welcome" | "download_mobile_app" | "complete_profile" | "slack_invite" | "add_events_to_calendar" | "connect_slack" | "%future added value";
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "%future added value";
export type ContentUsageSubmissionMethod = "submission" | "mark_as_complete" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CurriculumDrawerFooterContentFragment$data = {
  readonly id: string;
  readonly viewerHasCompleted: boolean;
  readonly content: {
    readonly type: ContentType;
    readonly systemTaskKind: ContentSystemTaskKind | null;
  };
  readonly isLocked: boolean;
  readonly submissionMethod: ContentUsageSubmissionMethod | null;
  readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment" | "ContentUsageMarkAsCompleteButtonFragment">;
  readonly " $fragmentType": "CurriculumDrawerFooterContentFragment";
};
export type CurriculumDrawerFooterContentFragment$key = {
  readonly " $data"?: CurriculumDrawerFooterContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurriculumDrawerFooterContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurriculumDrawerFooterContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerHasCompleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "systemTaskKind",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submissionMethod",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentUsageMarkAsCompleteButtonFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "d7c5f85ad8901ab3d9a9bacef94a152e";

export default node;
